//import { Softwares } from 'components/Software';
import React, { Fragment } from "react";
import { isMobile } from 'react-device-detect';
import { Container, Row, Col } from "reactstrap";

const SectionLink = ({ sectionSpace, children }) => {

  return (
    <Fragment>
      <section
        id="features-section"
        className={`features-section ${sectionSpace} clearfix`}
      >
        <Container>
          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="8">
                <div className="feature-content">
                  <div className="section-title mb-60">
                    <span className="sub-title mb-15">Dashboard</span>
                    <h2 className="title-text mb-0">
                      Gerencie os seus pedidos {" "}
                      <span>de delivery</span>
                    </h2>
                  </div>

                  <p className="mb-30">
                    O Garçom Web Link, conta com um portal exclusivo para a administração e gestão
                    do seu delivery. Ele contém gráficos detalhados, mensais, diário, e até horário. Trazendo comparativos
                    entre um ano e outro, e até mesmo entre semanas.
                  </p>
                  <p className="mb-60">
                    Visualize todas as informações detalhadas do seus pedidos, clientes, cardápio, determine
                    o tipo da taxa de entrega, área de atendimento e muito mais.
                  </p>

                  <div className="btns-group ul-li clearfix">
                    <ul className="clearfix">
                      <li>
                        <a
                          rel="noopener noreferrer"
                          href={`https://${isMobile ? 'api' : 'web'}.whatsapp.com/send?phone=+5511971421566&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Gar%C3%A7om%20Web`}
                          target="_blank"
                          className="custom-btn-underline"
                          style={{ textTransform: 'none' }}
                        >
                          Falar com Consultor
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg="5" md="9">
                <div className="feature-image-7 float-right">
                  <span
                    className="bg-image"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img
                      src="assets/images/features/animation/9-img-1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="big-chart"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <img
                      src="assets/images/features/animation/gw-link1.3.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="circle-chart-1"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    <img
                      src="assets/images/features/animation/9-img-3.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="circle-chart-2"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <img
                      src="assets/images/features/animation/9-img-4.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="circle-chart-3"
                    data-aos="fade-up"
                    data-aos-delay="700"
                  >
                    <img
                      src="assets/images/features/animation/9-img-5.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="man-image-1"
                    data-aos="fade-right"
                    data-aos-delay="900"
                  >
                    <img
                      src="assets/images/features/animation/9-img-6.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="man-image-2"
                    data-aos="fade-right"
                    data-aos-delay="1000"
                  >
                    <img
                      src="assets/images/features/animation/9-img-7.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="man-image-3"
                    data-aos="fade-left"
                    data-aos-delay="1200"
                  >
                    <img
                      src="assets/images/features/animation/9-img-8.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="leaf-image"
                    data-aos="flip-left"
                    data-aos-delay="1300"
                  >
                    <img
                      src="assets/images/features/animation/9-img-9.png"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </div>

          <div className="feature-item" style={{ paddingBottom: 160 }}>
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="9">
                <div className="feature-image-7 float-left">
                  <span
                    className="bg-image"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img
                      src="assets/images/features/animation/9-img-10.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="big-chart"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <img
                      src="assets/images/features/animation/gw-link1.4.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="man-image-1"
                    data-aos="fade-right"
                    data-aos-delay="900"
                  >
                    <img
                      src="assets/images/features/animation/9-img-12.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="man-image-2"
                    data-aos="fade-right"
                    data-aos-delay="1000"
                  >
                    <img
                      src="assets/images/features/animation/9-img-14.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="man-image-3"
                    data-aos="fade-left"
                    data-aos-delay="1200"
                  >
                    <img
                      src="assets/images/features/animation/9-img-16.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="leaf-image"
                    data-aos="flip-left"
                    data-aos-delay="1300"
                  >
                    <img
                      src="assets/images/features/animation/9-img-15.png"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>

              <Col lg="6" md="8">
                <div className="feature-content">

                  <div className="section-title mb-30">
                    <span className="sub-title mb-15">
                      Delivery
                    </span>
                    <h2 className="title-text mb-0">
                      No Celular e no PC
                      {/* <span>featureDataTwo.span</span> */}
                    </h2>
                  </div>


                  <p className="mb-30">
                    O Garçom Web Link, pode ser acessado de qualquer dispositivo windows ou mobile (android e iOS),
                    tornando prático, não requerendo instalação.
                  </p>

                  <div className="info-list ul-li-block mb-45 clearfix">
                    <ul className="clearfix">
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Integre ao seu site, disponibilizando um botão para acessar o Delivery.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Fácil de divulgar e compartilhar, tanto em formato de link quanto QRCode.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Um único site, muitas possíbilidades. Use como delivery, e/ou auto atendimento.
                        </span>
                      </li>
                    </ul>
                  </div>

                  <a
                    rel="noopener noreferrer"
                    href={`https://${isMobile ? 'api' : 'web'}.whatsapp.com/send?phone=+5511971421566&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Gar%C3%A7om%20Web`}
                    target="_blank"
                    className="custom-btn-underline"
                    style={{ textTransform: 'none' }}
                  >
                    Falar com Consultor
                  </a>
                  {/* 
                  <Link to="/pricing-page" className="custom-btn-underline m-0">
                    Start 30 Days Free Trail
                  </Link> */}
                </div>
              </Col>
            </Row>
          </div>

        </Container>

        {children}

        <Container>
          <div className="feature-item" style={{ paddingTop: 160 }}>
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="8">
                <div className="feature-content">

                  <div className="section-title mb-30">
                    <span className="sub-title mb-15">
                      Apple Store & Google Play
                    </span>
                    <h2 className="title-text mb-0">
                      iOS & Android
                    </h2>
                  </div>

                  <p className="mb-30">
                    Comece com um site de delivery e caso queira, expanda publicando seu próprio App
                    na Play Store e Apple Store.
                  </p>

                  <div className="info-list ul-li-block mb-45 clearfix">
                    <ul className="clearfix">
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Fique livre das taxas dos apps terceiros.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Sua marca cresce e se fortalece.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Seu app não precisará ser encontrado dentro de um marketplace, que exibe milhares de outros concorrentes seus.
                        </span>
                      </li>
                    </ul>
                  </div>
                  {/* 
                  <Link to="/pricing-page" className="custom-btn-underline m-0">
                    Start 30 Days Free Trail
                  </Link> */}
                </div>
              </Col>

              <Col lg="5" md="5">
                <div className="feature-image-1" >
                  <span className="circle-image">
                    <img
                      src="assets/images/features/animation/gw-comanda-1.1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="phone-image"
                    data-aos="zoom-in"
                    data-aos-delay="800"
                  >
                    <img
                      src="assets/images/features/animation/gw-link1.5.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="comment-image-1"
                    data-aos="fade-right"
                    data-aos-delay="1100"
                  >
                    <img
                      src="assets/images/features/animation/gw-link1.6.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="comment-image-2"
                    data-aos="fade-right"
                    data-aos-delay="1300"
                  >
                    <img
                      src="assets/images/features/animation/gw-link1.8.png"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>

            </Row>
          </div>


        </Container>




      </section>
    </Fragment>
  );
};

export default SectionLink;
