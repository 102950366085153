import React, { Fragment } from "react";
import PageTitle from "../../components/utility";
import { LayoutFluid } from "../../layouts";
import BannerLink from '../../components/Banner/BannerLink';
import HowItWorksGwLink from '../../components/WorkingProgress/HowItWorksGwLink';
import SectionLink from '../../components/Features/SectionLink';
import { Softwares } from 'components/Software';
import { GwLinkPagamentoOnline } from 'components/WorkingProgress';
import GwLinkPricing from 'pages/others/GwLinkPricing';

const GwLinkPage = () => {

  return (
    <Fragment>
      <PageTitle
        PageTitle="Garçom Web Link"
        favicon="assets/images/logo/garcom-link.png"
      />
      <LayoutFluid
        activeClass="active"
        wrapperClass="home-page-6"
        sectionSpace="sec-ptb-160 pb-0"
        whitekContent="black-content"
      >
        <main>
          <BannerLink sectionSpace="mb-60" />
          {/* <ServiceCardOne sectionSpace="mb-100" /> */}
          <HowItWorksGwLink sectionSpace="sec-ptb-160" />
          <SectionLink sectionSpace="sec-ptb-160 pt-0" >
            <Softwares sectionSpace="sec-ptb-160 " />
          </SectionLink>

          <GwLinkPagamentoOnline />
          {/* <GwLinkPricing /> */}


          {/* <FeatureSix sectionSpace="sec-ptb-160 pt-0" /> */}

          {/* <AppSectionThree sectionSpace="sec-ptb-160" /> */}
        </main>
      </LayoutFluid>
    </Fragment>
  );
};

export default GwLinkPage;
