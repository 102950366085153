import React, { useState } from "react";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";

import classnames from "classnames";
import TitleSection from "../Titlesection/TitleSection";
//import titleTwo from "../../data/title/title-two.json";

//const [{ title, Paragraph }] = titleTwo;

const FeaturesNav = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <section
        id="extra-features-section"
        className="extra-features-section sec-ptb-160 bg-light-gray clearfix"
      >
        <Container>
          <Row className="justify-content-center">
            <Col sm="12" lg="6" md="8">
              {
                <TitleSection spacer="mb-100" textAlign="text-center">
                  <h2 className="title-text mb-30">Nossos Serviços</h2>
                  <p className="paragraph-text mb-0">Possuímos mais de 20 anos de experiência,<span>Experiência esta, que justifica o nosso sucesso.</span></p>
                  {/* <p className="paragraph-text mb-0">Mais do que tempo, um largo e vasto Know-How, <span>que justificam o nosso sucesso.</span></p> */}
                </TitleSection>
              }
            </Col>
          </Row>

          <div className="features-nav ul-li-center mb-100">
            {/* class="nav" */}
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  <span className="item-icon">
                    <img
                      src="assets/fonts/svg/filled/Interaction.svg"
                      alt="icon_not_found"
                    />
                  </span>
                  instalação
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  <span className="item-icon">
                    <img
                      src="assets/fonts/svg/filled/Floder.svg"
                      alt="icon_not_found"
                    />
                  </span>
                  treinamento
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    toggle("3");
                  }}
                  style={{ textTransform: 'none' }}
                >
                  <span className="item-icon">
                    <img
                      src="assets/fonts/svg/filled/Pie_Chart.svg"
                      alt="icon_not_found"
                    />
                  </span>
                  Acompanhamento
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row className="justify-content-lg-start justify-content-md-center">
                <Col sm="12" md="8" lg="6">
                  <div className="feature-content">
                    <TitleSection spacer="mb-50" >
                      {/* <span className="sub-title mb-15">Garçom Web Office</span> */}
                      <h3 className="item-title mb-30">Instalação e Configuração</h3>
                      <p className="paragraph-text mb-30">Realizamos a instalação e configuração do Garçom Web. A configuração da rede (roteadores, switch, access point, computador caixa, servidor, etc.) ja deve estar previamente configurada, para que tudo fique pronto para o treinamento operacional.</p>
                    </TitleSection>
                    <div className="instructions-list ul-li-block">
                      <ul className="clearfix">
                        <li>
                          <i className="flaticon-checked"></i>
                          Instalação do Sistema Garçom Web
                        </li>
                        <li>
                          <i className="flaticon-checked"></i>
                          Configuração do Sistema Garçom Web
                        </li>
                        <li>
                          <i className="flaticon-checked"></i>
                          Instalação dos Aplicativos Garçom Web
                        </li>
                        <li>
                          <i className="flaticon-checked"></i>
                          Configuração das Impressoras
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" lg="6" md="8">
                  <div className="feature-image text-center">
                    <img
                      src="assets/images/features/img-2.png"
                      alt="image_not_found"
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row className="justify-content-lg-start justify-content-md-center">
                <Col sm="12" md="8" lg="6">
                  <div className="feature-content">
                    <h3 className="item-title mb-30">
                      Treinamento Operacional
                      {/* <span className="break-point">
                        user friendly app Features
                      </span> */}
                    </h3>
                    <p className="mb-30">
                      Treinamos toda a brigada, desde o operador do caixa, garçons e gerencia, ou seja,
                      todo e qualquer funcionário que necessite operar o sistema.
                    </p>
                    {/* <div className="instructions-list ul-li-block ">
                      <ul className="clearfix">
                        <li>
                          <i className="flaticon-checked"></i>
                          Financeiro
                        </li>
                        <li>
                          <i className="flaticon-checked"></i>
                          Compras
                        </li>
                        <li>
                          <i className="flaticon-checked"></i>
                          Estoque
                        </li>
                        <li className='mb-30'>
                          <i className="flaticon-checked"></i>
                          asd
                        </li>
                      </ul>
                    </div> */}

                    <h3 className="item-title mb-30">
                      Treinamento Administrativo
                      {/* <span className="break-point">
                        user friendly app Features
                      </span> */}
                    </h3>
                    <p className="mb-30">
                      O proprietário ou o resposável pelo administrativo,
                      irá aprender a lançar seu contas a pagar e receber,
                      além de controlar o estoque e compras, e ter acesso a uma gama de relatórios tais como, faturamento,
                      vendas, compras, balancete, demonstrativo de resultados, dentre outros.
                    </p>

                    <div className="feature-content mb-30">
                      <h3 className="item-title mb-30">
                        Consultoria
                        {/* <span className="break-point">
                        Resultado
                      </span> */}
                      </h3>
                      <p className="mb-30">
                        Serviço personalizado que temos, que visa um suporte mais profundo em busca de analisar
                        a operação do dia num todo, desde a operação do caixa até o compras e contas a pagar,
                        detectar falhas e erros humanos no processo, e trazer a solução para o problema e conseguir fazer
                        com que o proprietário tenha a empresa literalmente nas mãos, sabendo tudo que acontece e com relatórios
                        consistentes.
                      </p>

                    </div>

                  </div>
                </Col>
                <Col sm="12" lg="6" md="8">
                  <div className="feature-image text-center">
                    <img
                      src="assets/images/features/img-1.png"
                      alt="image_not_found"
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row className="justify-content-lg-start justify-content-md-center">
                <Col sm="12" md="8" lg="6">
                  <div className="feature-content mb-30">
                    <h3 className="item-title mb-30">
                      Acompanhamento Operacional
                      {/* <span className="break-point">
                        Resultado
                      </span> */}
                    </h3>
                    <p className="mb-30">
                      O Acompanhamento é realizado no dia da inauguração da "casa",
                      isso consiste em ter um ou mais a disposição neste dia para que sejam
                      sanadas dúvidas que ainda possam ter ficado mesmo após o treinamento operacional.
                    </p>

                    {/* <div className="instructions-list ul-li-block">
                      <ul className="clearfix">
                        <li>
                          <i className="flaticon-checked"></i>
                          Acompanha o movimento durante um período pré-acordado.
                        </li>
                        <li>
                          <i className="flaticon-checked"></i>
                          Sana dúvidas operacionais.
                        </li>
                        <li>
                          <i className="flaticon-checked"></i>
                          Auxilia no fechamento do caixa
                        </li>
                        <li>
                          <i className="flaticon-checked"></i>
                          Explica a fita de fechamento
                        </li>
                      </ul>
                    </div> */}
                  </div>

                  <div className="feature-content">
                    <h3 className="item-title mb-30">
                      Suporte Remoto
                      {/* <span className="break-point">
                        Resultado
                      </span> */}
                    </h3>
                    <p className="mb-30">
                      O suporte remoto é realizado para sanar dúvidas (não para dar treinamento),
                      e realizar algum tipo de manutenção no sistema, seja preventiva ou corretiva.
                      Além de realizar atualizações no software.
                    </p>
                  </div>
                  <div className="feature-content">
                    <h3 className="item-title mb-30">
                      Suporte Local
                      {/* <span className="break-point">
                        Resultado
                      </span> */}
                    </h3>
                    <p className="mb-30">
                      O Suporte local só é necessário caso ocorra algum tipo de problema que não possa
                      ser resolvido por acesso remoto, lembrando que não prestamos manutenção em hardware,
                      somente no nosso software.
                    </p>

                  </div>
                </Col>

                <Col sm="12" lg="6" md="8">
                  <div className="feature-image text-center">
                    <img
                      src="assets/images/features/img-3.png"
                      alt="image_not_found"
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Container>
      </section>
    </div>
  );
};

export default FeaturesNav;
