import React, { Fragment } from "react";
import { isMobile } from 'react-device-detect';
import { Container, Row, Col } from "reactstrap";
// import featureDataOne from "../../data/feature/feature-one.json";
// import featureDataTwo from "../../data/feature/feature-two.json";
// const [{ paragraph1, paragraph2, subTitle, title, span }] = featureDataOne;

const SectionComanda = ({ sectionSpace }) => {

  return (
    <Fragment>
      <section
        id="features-section"
        className={`features-section ${sectionSpace} clearfix`}
      >
        <Container>
          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="5">
                <div className="feature-image-1">
                  <span className="circle-image" >
                    <img
                      src="assets/images/features/animation/gw-fast-1.1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="phone-image"
                    data-aos="zoom-out"
                    data-aos-delay="600"
                  >
                    <img
                      src="assets/images/features/animation/gw-live-4.png"
                      alt="image_not_found"
                    />
                  </span>
                  {/* <span
                    className="comment-image-1"
                    data-aos="fade-left"
                    data-aos-delay="800"
                    style={{ top: 355, left: 50 }}
                  >
                    <img
                      src="assets/images/features/animation/gw-live-5.png"
                      alt="image_not_found"
                    />
                  </span> */}
                  {/* <span
                    className="comment-image-2"
                    data-aos="fade-left"
                    data-aos-delay="1000"
                    style={{ top: 100, left: 30 }}
                  >
                    <img
                      src="assets/images/features/animation/gw-live-5.png"
                      alt="image_not_found"
                    />
                  </span> */}
                </div>
              </Col>

              <Col lg="6" md="8">
                <div className="feature-content">
                  <div className="section-title mb-30">
                    <span className="sub-title mb-15">Movimento</span>
                    <h2 className="title-text mb-0">
                      Mesas e entregas em aberto {" "}
                      {/* <span>asdasd</span> */}
                    </h2>
                  </div>

                  <p className="mb-60">Saiba como anda o movimento da sua empresa. <br />
                    Visualize suas mesas e entregas em aberto e muito mais.</p>

                  <div className="info-list ul-li-block mb-45 clearfix">
                    <ul className="clearfix">
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Quantidade de Mesas e Entregas em Aberto.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Valor Total das Mesas e Entregas em Aberto.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          "Entre" na mesa, ou entrega, e visualize cada produto vendido.
                        </span>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="btns-group ul-li clearfix">
                    <ul className="clearfix">
                      <li>
                        <Link
                          to="/features-app-landing-creative"
                          className="custom-btn"
                        >
                          See All Features
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/features-saas-landing-creative"
                          className="custom-btn-underline"
                        >
                          See All Features
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </Col>
            </Row>
          </div>

          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="8">
                <div className="feature-content">

                  <div className="section-title mb-30">
                    <span className="sub-title mb-15">
                      Vendas
                    </span>
                    <h2 className="title-text mb-0">
                      Produtos Vendidos
                      {/* <span>featureDataTwo.span</span> */}
                    </h2>
                  </div>


                  <p className="mb-30">
                    Saiba através do gráfico, quais são os grupos de produtos que mais vendem.
                  </p>
                  <p className="mb-60">
                    Veja também em formato de listagem todos os produtos vendidos do dia.
                  </p>

                  <div className="info-list ul-li-block mb-45 clearfix">
                    <ul className="clearfix">
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Saiba quantas pessoas passaram pelo estabelecimento e qual é o valor do ticket médio.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Veja quais produtos vendidos foram cancelados.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          E saiba também se alguma venda balcão, foi esquecida em espera.
                        </span>
                      </li>
                    </ul>
                  </div>
                  {/* 
                  <Link to="/pricing-page" className="custom-btn-underline m-0">
                    Start 30 Days Free Trail
                  </Link> */}
                </div>
              </Col>

              <Col lg="5" md="5">
                <div className="feature-image-1" >
                  <span className="circle-image">
                    <img
                      src="assets/images/features/animation/gw-fast-1.1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="phone-image"
                    data-aos="zoom-in"
                    data-aos-delay="800"
                  >
                    <img
                      src="assets/images/features/animation/gw-live-6.png"
                      alt="image_not_found"
                    />
                  </span>
                  {/* <span
                    className="comment-image-1"
                    data-aos="fade-right"
                    data-aos-delay="1100"
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="comment-image-2"
                    data-aos="fade-right"
                    data-aos-delay="1300"
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda1.1.png"
                      alt="image_not_found"
                    />
                  </span> */}
                </div>
              </Col>
            </Row>
          </div>




          <div className='text-center'>
            <h2 className='title-text mb-30'>Ficou alguma dúvida? </h2>
            <a href={`https://${isMobile ? 'api' : 'web'}.whatsapp.com/send?phone=+5511971421566&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Gar%C3%A7om%20Web`} target='_blank' rel="noopener noreferrer" className="custom-btn-underline m-0" style={{ textTransform: 'none' }}>
              Falar com um consultor
            </a>
          </div>
        </Container>
      </section>
    </Fragment>
  );
};

export default SectionComanda;
