import React from "react";
import TitleSection from "../Titlesection/TitleSection";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
// import titleOne from "../../data/software/software-section-one.json";
// const [{ title, subTitle, text }] = titleOne;

const Softwares = ({ sectionSpace }) => {

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -120;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth', block: "start" });
  }

  return (
    <section
      id="software-section"
      className={`software-section ${sectionSpace} clearfix`}
      style={{ backgroundImage: `url("assets/images/software/sec-bg-2.jpg")` }}>
      <span
        className="shape-img-1"
        data-aos="fade-right"
        data-aos-duration="1200"
        data-aos-delay="300"
      >
        <img src="assets/images/software/shape-3.png" alt="logo_not_found" />
      </span>
      <span
        className="shape-img-2"
        data-aos="fade-left"
        data-aos-duration="1200"
        data-aos-delay="300"
      >
        <img src="assets/images/software/shape-4.png" alt="logo_not_found" />
      </span>
      <Container>
        <Row className="justify-content-center">
          <Col sm="12" lg="6" md="8">
            <TitleSection spacer="mb-100" textAlign="text-center">
              <span className="sub-title mb-15">Integração</span>
              <h2 className="title-text mb-30" style={{ color: "#272d3a" }}>Totalmente integrado ao nosso Sistema de Gestão</h2>
              <p className="paragraph-text mb-0" style={{ color: "#272d3a" }}>O Garçom Web Link, funciona independente, sendo gerido totalmente através do portal, mas ele já vem integrado a todo linha Garçom Web.</p>
            </TitleSection>
          </Col>
          <Col lg="8" xs="12">
            <div className="software-container">
              <div
                className="software-item"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="200"
                title='Garçom Web Vendas'
              >
                <HashLink smooth to="/home#mesa" scroll={el => scrollWithOffset(el)} className="software-logo" style={{ padding: 38 }}>
                  <img
                    //src="assets/images/software/logo-1.png"
                    src="assets/images/software/gw_pdv.png"
                    width="50px"
                    height="50px"
                    alt="logo_not_found"
                  />
                </HashLink>
                {/* <Link to="/garcom-web-vendas" className="software-logo" style={{ padding: 38 }} >
                  <img
                    //src="assets/images/software/logo-1.png"
                    src="assets/images/software/gw_pdv.png"
                    width="50px"
                    height="50px"
                    alt="logo_not_found"
                  />
                </Link> */}
              </div>
              <div
                className="software-item"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="300"
                title='Garçom Web Micro Terminal'
              >
                <Link to="/" className="software-logo" style={{ padding: 15 }}>
                  <img
                    src="assets/images/software/gw_terminal.png"
                    alt="logo_not_found"
                  />
                </Link>
              </div>
              <div
                className="software-item"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="500"
                title='Garçom Web Office'
              >
                <Link to="/" className="software-logo" style={{ padding: 15 }}>
                  <img
                    src="assets/images/software/gw_office.png"
                    alt="logo_not_found"
                  />
                </Link>
              </div>
              <div
                className="software-item"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="700"
                title='Garçom Web Link'
              >

                <Link to="/garcom-web-link" className="software-logo" style={{ padding: 15 }}>
                  <img
                    src="assets/images/software/www.png"
                    alt="logo_not_found"
                  />
                </Link>
              </div>
              <div
                className="software-item"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="900"
                title="Integração com Market Place"
              >
                <HashLink smooth to="/home#delivery" scroll={el => scrollWithOffset(el)} className="software-logo" style={{ padding: 15 }}>
                  <img
                    src="assets/images/software/gw_monitor_pedidos.png"
                    alt="logo_not_found"
                  />
                </HashLink>
                {/* <Link to="/" className="software-logo" style={{ padding: 15 }} >
                  <img
                    src="assets/images/software/gw_monitor_pedidos.png"
                    alt="logo_not_found"
                  />
                </Link> */}
              </div>
              <div
                className="software-item"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="1100"
                title='Garçom Web Live'
              >
                <Link to="/garcom-web-live" className="software-logo" style={{ padding: 25 }}>
                  <img
                    src="assets/images/software/gw_live.png"
                    alt="logo_not_found"
                  />
                </Link>
              </div>
              <div
                className="software-item"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="1300"
                title='Garçom Web Comanda'
              >
                <Link to="/garcom-web-comanda" className="software-logo" style={{ padding: 25 }}>
                  <img
                    src="assets/images/software/smartphone.png"
                    alt="logo_not_found"
                  />
                </Link>
              </div>
            </div>
          </Col>
          {/* <Col lg="8" xs="12">
            <div className="software-container">
              <div
                className="software-item"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <Link to="#" className="software-logo">
                  <img
                    src="assets/images/software/logo-1.png"
                    alt="logo_not_found"
                  />
                </Link>
              </div>
              <div
                className="software-item"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <Link to="#" className="software-logo">
                  <img
                    src="assets/images/software/logo-2.png"
                    alt="logo_not_found"
                  />
                </Link>
              </div>
              <div
                className="software-item"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <Link to="#" className="software-logo">
                  <img
                    src="assets/images/software/logo-3.png"
                    alt="logo_not_found"
                  />
                </Link>
              </div>
              <div
                className="software-item"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="700"
              >
                <Link to="#" className="software-logo">
                  <img
                    src="assets/images/software/logo-4.png"
                    alt="logo_not_found"
                  />
                </Link>
              </div>
              <div
                className="software-item"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="900"
              >
                <Link to="#" className="software-logo">
                  <img
                    src="assets/images/software/logo-5.png"
                    alt="logo_not_found"
                  />
                </Link>
              </div>
              <div
                className="software-item"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="1100"
              >
                <Link to="#" className="software-logo">
                  <img
                    src="assets/images/software/logo-6.png"
                    alt="logo_not_found"
                  />
                </Link>
              </div>
              <div
                className="software-item"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="1300"
              >
                <Link to="#" className="software-logo">
                  <img
                    src="assets/images/software/logo-7.png"
                    alt="logo_not_found"
                  />
                </Link>
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
    </section>
  );
};

export default Softwares;
