import React, { Fragment } from "react";
import PageTitle from "../../components/utility";
import { LayoutFluid } from "../../layouts";
import { BrandSliderTwo } from "../../components/BrandSlider";
import { ServiceCardOne } from "../../components/ServiceCard";
import FeaturesNav from "../../components/FeaturesNav/FeaturesNav";
import { SectionApps } from "../../components/Features";
import { SoftwareSectionThree } from "../../components/Software";
import FanFact from "../../components/FuncFact/FanFact";
//import { FreeTrailOne } from "../../components/FreeTrail";
import { FaqSectionOne } from "../../components/FaqSection";
import { WorkingProgressOne } from '../../components/WorkingProgress';
import HomeSwiper from '../../components/Banner/BannerHome';
import SectionModulos from '../../components/Features/SectionModulos';

const Home = () => {

  return (
    <Fragment>
      <PageTitle
        PageTitle="Home - Garçom Web"
        favicon="assets/images/logo/live.png"
      />
      <LayoutFluid
        activeClass="active"
        wrapperClass="home-page-11"
        sectionSpace="sec-ptb-160 pb-0"
      >
        <main>
          {/* <BannerTen /> */}
          <HomeSwiper />
          <SectionModulos sectionSpace="sec-ptb-160" />

          <BrandSliderTwo />
          <WorkingProgressOne sectionSpace="sec-ptb-160" />
          <ServiceCardOne sectionSpace="sec-ptb-160" />


          <FeaturesNav />

          <SectionApps />
          {/* <FeatureNine sectionSpace="sec-ptb-160" /> */}
          <SoftwareSectionThree sectionSpace="sec-ptb-160" />
          {/* <TestimonialSliderTwo sectionSpace="sec-ptb-160 pb-0" /> */}
          <FanFact />
          {/* <PricingOne pricingBg="assets/images/pricing/sec-bg-1.jpg" /> */}
          {/* <FreeTrailOne sectionSpace="sec-ptb-160" /> */}
          <FaqSectionOne />
          {/* <BlogSlider sectionSpace="sec-ptb-160 pb-0" /> */}
        </main>
      </LayoutFluid>
    </Fragment>
  );
};

export default Home;
