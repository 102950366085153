import React from "react";
//import { Link } from "react-router-dom";
import TitleSection from "../Titlesection/TitleSection";
import { Container, Row, Col } from "reactstrap";

// import titleOne from "../../data/WorkingProgress/working-progress-two.json";
// const [{ title, subTitle, text }] = titleOne;


const HowItWorksGwComanda = ({ sectionSpace }) => {


  return (
    <section
      id="working-process-section"
      className={`working-process-section ${sectionSpace} clearfix`}
      style={{ paddingTop: 60 }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col sm="12" lg="6" md="8">
            <TitleSection spacer="mb-100" textAlign="text-center">
              <span className="sub-title mb-15">Como funciona</span>
              <h2 className="title-text mb-30">Como o nosso app pode te ajudar</h2>
              <p className="paragraph-text mb-0">Por ele ter um design simples, moderno e amigável, isso faz dele, um app muito fácil de operar por qualquer pessoa. </p>
            </TitleSection>
          </Col>
        </Row>

        <Row className="justify-content-lg-start justify-content-md-center">
          <Col lg="4" md="8" xs="12">
            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/icon-6.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Permissões</h3>
                <p className="mb-0">
                  Gerencie o que cada operador pode fazer. Por exemplo,
                  tirar pedido, fechar a conta, excluir produtos, transferir itens.
                </p>
              </div>
            </div>

            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/icon-7.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Procura</h3>
                <p className="mb-0">
                  Encontre fácil o produto. Pela categoria,
                  descrição, código, ou até mesmo, utilizando a câmera para ler o QRCode.
                </p>
              </div>
            </div>
          </Col>

          <Col lg="4" md="8" xs="12">
            <div className="process-phone-image" >
              <span
                className="bg-image"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <img
                  src="assets/images/features/animation/6-img-1.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="phone-image"
                data-aos="zoom-in"
                data-aos-delay="700"
              >
                <img
                  src="assets/images/features/animation/gw-comanda-overall.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="shape-image-1"
                data-aos="fade-right"
                data-aos-delay="900"
              >
                <img
                  src="assets/images/features/animation/6-img-2.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="shape-image-2"
                data-aos="fade-left"
                data-aos-delay="1100"
              >
                <img
                  src="assets/images/features/animation/6-img-4.png"
                  alt="image_not_found"
                />
              </span>
            </div>
          </Col>

          <Col lg="4" md="8" xs="12">
            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/icon-5.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Certificado aos POS</h3>
                <p className="mb-0">
                  PagSeguro, Rede, Getnet, Stone. Tire o pedido do cliente e também realize o pagamento. Tudo sem sair do nosso app.
                </p>
              </div>
            </div>

            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/icon-8.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Mensagens</h3>
                <p className="mb-0">
                  Comunique-se facilmente com os demais operadores, para avisá-los sobre produtos que estão em falta por exemplo.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <div className="seemore-btn text-center">
          {/* <Link to="#" className="custom-btn-underline">
            see all features
          </Link> */}
        </div>
      </Container>
    </section>
  );
};

export default HowItWorksGwComanda;
