import React, { Fragment } from "react";
import PageTitle from "../../components/utility";
import { LayoutFluid } from "../../layouts";
import BannerComanda from '../../components/Banner/BannerComanda';
import HowItWorksGwLive from '../../components/WorkingProgress/HowItWorksGwLive';
import SectionLive from '../../components/Features/SectionLive';

const GwLivePage = () => {

  return (
    <Fragment>
      <PageTitle
        PageTitle="Garçom Web Live"
        favicon="assets/images/logo/live.png"
      />
      <LayoutFluid
        activeClass="active"
        wrapperClass="home-page-6"
        sectionSpace="sec-ptb-160 pb-0"
        whitekContent="black-content"
      >
        <main>
          <BannerComanda sectionSpace="mb-60" live />
          {/* <ServiceCardOne sectionSpace="mb-100" /> */}
          <HowItWorksGwLive sectionSpace="sec-ptb-160" />
          <SectionLive />
          {/* <FeatureSix sectionSpace="sec-ptb-160 pt-0" /> */}

          {/* <AppSectionThree sectionSpace="sec-ptb-160" /> */}
        </main>
      </LayoutFluid>
    </Fragment>
  );
};

export default GwLivePage;
