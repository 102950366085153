import React from "react";
//import { Link } from "react-router-dom";
import TitleSection from "../Titlesection/TitleSection";
import { Container, Row, Col } from "reactstrap";

// import titleOne from "../../data/WorkingProgress/working-progress-two.json";
// const [{ title, subTitle, text }] = titleOne;


const HowItWorksGwFast = ({ sectionSpace }) => {


  return (
    <section
      id="working-process-section"
      className={`working-process-section ${sectionSpace} clearfix`}
      style={{ paddingTop: 60 }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col sm="12" lg="6" md="8">
            <TitleSection spacer="mb-100" textAlign="text-center">
              <span className="sub-title mb-15">Como funciona</span>
              <h2 className="title-text mb-30">Como o nosso app pode te ajudar</h2>
              <p className="paragraph-text mb-0">
                O Garçom Web Fast, tem este nome pois visa agilizar processos que anteriormente
                eram muito burocráticos.
              </p>
            </TitleSection>
          </Col>
        </Row>

        <Row className="justify-content-lg-start justify-content-md-center">
          <Col lg="4" md="8" xs="12">
            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/caixa.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Caixa</h3>
                <p className="mb-0">
                  Potencialize o atendimento do seu estabelecimento.
                  Com o Garçom Web Fast, cada comanda pode se tornar um caixa
                </p>
              </div>
            </div>

            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/food.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Híbrido</h3>
                <p className="mb-0">
                  O Fast é o que você precisar, seja um caixa, uma comanda, atender balcão, mesa,
                  ou realizar pagamentos via cartão de crédito.
                </p>
              </div>
            </div>
          </Col>

          <Col lg="4" md="8" xs="12">
            <div className="process-phone-image" >
              <span
                className="bg-image"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <img
                  src="assets/images/features/animation/6-img-1.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="phone-image"
                data-aos="zoom-in"
                data-aos-delay="700"
              >
                <img
                  src="assets/images/features/animation/gw-fast.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="shape-image-1"
                data-aos="fade-right"
                data-aos-delay="900"
              >
                <img
                  src="assets/images/features/animation/6-img-2.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="shape-image-2"
                data-aos="fade-left"
                data-aos-delay="1100"
              >
                <img
                  src="assets/images/features/animation/6-img-4.png"
                  alt="image_not_found"
                />
              </span>
            </div>
          </Col>

          <Col lg="4" md="8" xs="12">
            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/icon-5.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Certificado aos POS</h3>
                <p className="mb-0">
                  PagSeguro, Rede, Getnet, Stone. Tire o pedido do cliente e também realize o pagamento. Tudo sem sair do nosso app.
                </p>
              </div>
            </div>

            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/conta.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Cupom Fiscal Eletrônico</h3>
                <p className="mb-0">
                  O Garçom Web Fast é um caixa completo, pois,
                  após realizar o pagamento da conta, ele faz a impressão do Cupom Fiscal.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <div className="seemore-btn text-center">
          {/* <Link to="#" className="custom-btn-underline">
            see all features
          </Link> */}
        </div>
      </Container>
    </section>
  );
};

export default HowItWorksGwFast;
