import React from "react";
import PageTitle from "../../components/utility";
import { LayoutFluid } from "../../layouts";
import { BreadCrumbOne } from "../../components/BreadCrumb";

import { Map } from "../../components/Map";

const ContactPage = () => {

  return (
    <React.Fragment>
      <PageTitle
        PageTitle="Contato - Garçom Web"
        favicon="assets/images/logo/live.png"
      />
      <LayoutFluid
        wrapperClass="contact-page"
        activeClass="active"
        //sectionSpace="sec-ptb-160 pb-0"
        whitekContent="white-content"
        logoWhite="assets/images/logo/logo-1.png"
      >
        <main>
          <BreadCrumbOne
            title="Entre em contato conosco"
            description2="Tel.: 11 3082-8660"
            description="contato@garcomweb.com.br"
            //span="Tel. 11 3082-8660"
            pageName="Contato"
            curentPageName="Contato"
            span={undefined}
            span2={undefined} />

          <Map />
        </main>
      </LayoutFluid>
    </React.Fragment>
  );
};

export default ContactPage;
