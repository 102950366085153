import React from "react";
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from "reactstrap";

const FaqSection = (props) => {
  return (
    <section
      id="faq-section"
      className="faq-section sec-ptb-160 bg-light-gray clearfix"
    >
      <Container>
        <Row className="justify-content-center">
          <Col lg="7" md="8">
            <div
              className="section-title mb-100 text-center"
              data-aos="fade-up"
              data-aos-duration="450"
              data-aos-delay="100"
            >
              <span className="sub-title mb-15">Alguma Dúvida?</span>
              <h2 className="title-text mb-30">Perguntas Frequentes</h2>
              <p className="paragraph-text mb-0"></p>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-lg-start justify-content-md-center">
          <Col lg="6" md="8">
            <div className="queistions-container mb-60">
              <div
                className="queistions-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="450"
                data-aos-delay="200"
              >
                <h3 className="item-title mb-15">
                  1. O que é o Garçom Web ?
                </h3>
                <p className="mb-0">
                  Garçom Web é um software de automação comercial, no qual existem diversos outros produtos que o compõe.
                  Sendo alguns deles, o Garçom Web Vendas, Office, Comanda, Fast, Live, Microterminal, Balanças, Link de Pedidos, dentro outros.
                </p>
              </div>

              <div
                className="queistions-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="450"
                data-aos-delay="300"
              >
                <h3 className="item-title mb-15">
                  2. Quais segmentos o Garçom Web atende ?
                </h3>
                <p className="mb-0">
                  Os Produtos Garçom Web, são voltados para o ramo alimentício e food service, e contém funcionalidades que facilitam a gestão
                  do negócio no dia a dia. Alguns dos segmentos atendidos são: Bares, Restaurantes, Lanchonetes, Padarias, Cafeterias,
                  Pizzarias, Lojas de Conveniência, Self Service, Quilo, Food Trucks, Rotisserias, dentre outros.
                </p>
              </div>

              <div
                className="queistions-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="450"
                data-aos-delay="400"
              >
                <h3 className="item-title mb-15">
                  3. Porque escolher o Garçom Web ?
                </h3>
                <p className="mb-0">
                  O Garçom Web surgiu entendendo as carências e necessidades do setor alimentício, e possui profissionais com alta experiência
                  no ramo de automação comercial e tecnologia. Isso tudo alinhado, faz com que nossos produtos estejam sempre evoluindo,
                  com atualizações constantes, sabendo ouvir o que o cliente precisa, e por ter um suporte especializado e ativo, faz do
                  Garçom Web uma escolha certa.
                </p>
              </div>

              <div
                className="queistions-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="450"
                data-aos-delay="500"
              >
                <h3 className="item-title mb-15">
                  4. Existe uma versão gratuita ou de teste ?
                </h3>
                <p className="mb-0">
                  O Garçom Web possui "um degustação" gratuita por um periodo de tempo,
                  para mais informações entre em <Link to="/contato" style={{ textTransform: 'none' }}>contato</Link> conosco.
                </p>
              </div>
            </div>
          </Col>
          <Col lg="6" md="8">
            <div className="queistions-container mb-100">
              <div
                className="queistions-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="450"
                data-aos-delay="100"
              >
                <h3 className="item-title mb-15">
                  5. Se a minha internet cair, eu fico sem sistema ?
                </h3>
                <p className="mb-0">
                  Não. Embora o sitema possua Web em seu nome, ele só precisará de internet uma vez ao mês,
                  no período de sua autenticação, e caso chegue a data e não haja internet, o sistema não irá parar,
                  mas notificará o usuário, que ele precisa de uma conexão de internet dentro de 7 dias. E se mesmo após os 7 dias,
                  o usuário ainda não tiver internet, bastará, ele levar o PC para algum ponto que tenha conexão e abrir o programa,
                  que ele o validará automaticamente, ou ainda, basta compartilhar a conexão de algum smartphone, com o PC, que o
                  programa o autenticará normalmente.
                </p>
              </div>

              <div
                className="queistions-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="450"
                data-aos-delay="200"
              >
                <h3 className="item-title mb-15">
                  6. O meu PC roda o Garçom Web ?
                </h3>
                <p className="mb-0">
                  Nosso programa não é um programa pesado, pelo contrário, ele é bem leve, mesmo assim existem alguns pré-requisitos para
                  que ele rode sem problemas, mas não preocupe, nossa equipe de técnicos, sempre fazem uma avaliação prévia.
                </p>
              </div>

              <div
                className="queistions-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="450"
                data-aos-delay="300"
              >
                <h3 className="item-title mb-15">
                  7. Qual o preço do Garçom Web ?
                </h3>
                <p className="mb-0">
                  O preço varia de solução para solução, pois, você pode começar pequeno, e pode ir expandindo a sua automação aos poucos.
                </p>
              </div>

              <div
                className="queistions-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="450"
                data-aos-delay="400"
              >
                <h3 className="item-title mb-15">
                  8. Como adiquirir o Garçom Web ?
                </h3>
                <p className="mb-0">
                  Utilize algum dos nossos canais de <Link to="/contato">contato</Link>
                  {" "}ou <a href={`https://${isMobile ? 'api' : 'web'}.whatsapp.com/send?phone=+5511971421566&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Gar%C3%A7om%20Web`} target='_blank' rel="noopener noreferrer" style={{ textTransform: 'none' }}>envie uma mensagem</a> diretamente a um de nossos representantes comerciais.
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col lg="4" md="6">
            <div
              className="contact-info text-center"
              data-aos="fade-up"
              data-aos-duration="450"
              data-aos-delay="500"
            >
              <p className="mb-0">
                Não teve sua dúvida respondida? {" "}
                <span>
                  <a href={`https://${isMobile ? 'api' : 'web'}.whatsapp.com/send?phone=+5511971421566&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Gar%C3%A7om%20Web`} target='_blank' rel="noopener noreferrer" style={{ textTransform: 'none' }}>Entre em Contato</a>
                </span>
                {" "}
                {/* <a href="mailto:xyz@gmail.com">xyz@gmail.com</a> */}
                ou ligue <a href="tel:+551130828660">+55 11 3082-8660</a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FaqSection;
