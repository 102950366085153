import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

class ContactForm extends React.Component {
  render() {
    let {
      handleSubmit,
      handleChange,
      isSubmitting,
      values,
      errors,
    } = this.props;
    return (
      <div className="contact-form">
        <h2 className="title-text mb-30">Entrar em Contato</h2>
        <form onSubmit={handleSubmit} method="post">
          <div className="form-item">
            <input
              type="text"
              name="nome"
              placeholder="nome Completo "
              value={values.nome}
              onChange={handleChange}
            />
            {errors.nome && <p className="text-danger">{errors.nome}</p>}
          </div>
          <div className="form-item">
            <input
              type="tel"
              name="telefone"
              placeholder="(ddd) + telefone "
              value={values.telefone}
              onChange={handleChange}
            />
            {errors.telefone && <p className="text-danger">{errors.telefone}</p>}
          </div>
          <div className="form-item">
            <input
              type="email"
              name="email"
              placeholder="email "
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && <p className="text-danger">{errors.email}</p>}
          </div>
          <div className="form-item">
            <input
              type="text"
              name="assunto"
              placeholder="assunto "
              value={values.assunto}
              onChange={handleChange}
            />
            {errors.assunto && <p className="text-danger">{errors.assunto}</p>}
          </div>
          <div className="form-textarea">
            <textarea
              name="mensagem"
              placeholder="mensagem "
              onChange={handleChange}
              value={values.mensagem}
            ></textarea>
            {errors.mensagem && (
              <p className="text-danger">{errors.mensagem}</p>
            )}
          </div>
          <button type="submit" className="custom-btn" name="submit">
            {isSubmitting ? "Enviando... " : "enviar mensagem"}
          </button>
        </form>
      </div>
    );
  }
}

const AppalForm = withFormik({
  mapPropsToValues: (props) => props.values,
  validationSchema: Yup.object().shape({
    nome: Yup.string().required("nome é obrigatório"),
    telefone: Yup.string()
      .matches(phoneRegExp, 'telefone inválido')
      .min(10, "telefone muito curto")
      .max(13, "telefone muito longo")
      .required("telefone é obrigatório"),
    assunto: Yup.string().required("assunto é obrigatório"),
    mensagem: Yup.string().required("mensagem é obrigátória"),
    email: Yup.string()
      .email("informe um email válido")
      .required("email é obrigatório"),
  }),
  handleSubmit: (values, { setSubmitting, resetForm, props }) => {
    props.handleSubmit(values, setSubmitting, resetForm);
  },
  displayName: "BaseRateForm",
})(ContactForm);

export default AppalForm;
