import React, { Fragment, useRef, useEffect } from "react";

import { Container, Row, Col } from "reactstrap";
import Parallax from "parallax-js";
import { isMobile } from 'react-device-detect';
//import { Link } from 'react-router-dom';

const BannerLink = ({ sectionSpace }) => {
  const scenElement = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(scenElement.current, {
      relativeInput: true,
    });
    parallaxInstance.enable();
    return () => parallaxInstance.disable();
  }, []);

  return (
    <Fragment>
      <section
        id="banner-section"
        className={`banner-section ${sectionSpace} clearfix`}
      >
        <div className="mobile-app-banner-6">
          <Container>
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="7" md="8" xs="12">
                {/* <!-- show on mobile device - start --> */}
                <div className="mobile-banner-image d-none">
                  <img
                    src={`assets/images/features/animation/gw-link.png`}
                    alt="image_not_found"
                  />
                </div>
                {/* <!-- show on mobile device - end --> */}
                <div className="banner-content">
                  {/* <span style={{
                    padding: '5px', paddingLeft: '20px', borderLeft: '3px solid #f6716f', fontSize: '16px',
                    color: '#f6716f', fontWeight: 500, textTransform: 'capitalize'
                  }}>
                    Garçom Web Comanda
                  </span> */}
                  <h2>
                    Garçom Web {" "}
                    <span>Link</span>
                  </h2>
                  <p>
                    Com o Garçom Web Link o seu cliente faz o pedido tanto de delivery no conforto da sua casa,
                    quanto o seu auto atendimento, aí no seu estabelecimento.
                  </p>
                  <div className="btns-group ul-li clearfix">
                    <ul className="clearfix">

                      {/* <li>
                        <a
                          rel="noopener noreferrer"
                          href={'https://apps.apple.com/br/app/gw-comanda/id1532524975?platform=iphone'}
                          target="_blank"
                          className="store-btn bg-default-black"
                        >
                          <span className="icon">
                            <i className="flaticon-paper-plane"></i>
                          </span>
                          <strong className="title-text">
                            <small>Criar Agora</small>
                            meu site grátis
                          </strong>
                        </a>
                      </li> */}
                      <li>
                        <a
                          rel="noopener noreferrer"
                          href={'https://garcomweb.com.br/loja/delivery/16/servindo-resultados'}
                          target="_blank"
                          className="store-btn bg-default-pink"
                        >
                          <span className="icon">
                            <i className="flaticon-startup"></i>
                          </span>
                          <strong className="title-text">
                            <small>Visualizar</small>
                            site modelo
                          </strong>
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="btns-group ul-li clearfix">
                    <ul className="clearfix">
                      <li>
                        <a
                          rel="noopener noreferrer"
                          href={`https://${isMobile ? 'api' : 'web'}.whatsapp.com/send?phone=+5511971421566&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Gar%C3%A7om%20Web`}
                          target="_blank"
                          className="store-btn bg-default-blue"
                        >
                          <strong className="title-text" style={{ textTransform: 'none' }}>
                            <small>falar agora com </small>
                            um Consultor
                          </strong>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </Col>

              <Col lg="3">
                <div
                  className="banner-image scene clearfix"
                  id="scene"
                  ref={scenElement}
                >
                  <span className="phone-image float-right" data-depth="0.2">
                    <small
                      data-aos="zoom-out"
                      data-aos-duration="500"
                      data-aos-delay="200"
                    >
                      <img
                        src={"assets/images/features/animation/gw-link.png"}
                        alt="image_not_found"
                      />
                    </small>
                  </span>
                  {/* <span className="commentbar-image-1" data-depth="0.5">
                    <small
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      data-aos-delay="1000"
                    >
                      <img
                        src="assets/images/banner/gw-office.png"
                        alt="image_not_found"
                      />
                    </small>
                  </span> */}
                  {/* <span className="commentbar-image-3" data-depth="0.5">
                    <small
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      data-aos-delay="700"
                    >
                      <img
                        src={`assets/images/features/animation/gw-link.png`}
                        alt="image_not_found"
                      />
                    </small>
                  </span> */}
                  {/* <span className="commentbar-image-2" data-depth="0.5">
                    <small
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      data-aos-delay="1000"
                    >

                      <img
                        src={`assets/images/features/animation/gw-link1.1.png`}
                        alt="image_not_found"
                      />

                    </small>
                  </span> */}

                </div>
              </Col>
            </Row>
          </Container>
          <span className="shape-bg-image">
            <img
              src="assets/images/banner/mab-6-img-1.png"
              //src="assets/images/banner/mab-4-img-1.png"
              alt="image_not_found"
            />
          </span>
        </div>
      </section>
    </Fragment>
  );
};

export default BannerLink;
