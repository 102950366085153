import React, { Fragment } from "react";
import PageTitle from "../../components/utility";
import { LayoutFluid } from "../../layouts";
import { BreadCrumbOne } from "../../components/BreadCrumb";
import { About } from "../../components/About";

const AboutPage = () => {
  return (
    <Fragment>
      <PageTitle
        PageTitle="Quem Somos - Garçom Web"
        favicon="assets/images/logo/live.png"
      />
      <LayoutFluid
        wrapperClass="about-page"
        activeClass="active"
        sectionSpace="sec-ptb-160 pb-0"
        whitekContent="white-content"
        logoWhite="assets/images/logo/logo-1.png"
      >
        <main>
          <BreadCrumbOne
            title="Somos mais que um time"
            description="Somos uma Família!"
            // description="Descomplicamos a Automação Comercial, para que"
            // span="as tomadas de decisões sejam rápidas e práticas"
            pageName="Quem Somos"
            curentPageName="quem somos"
          />


          <About sectionSpace="sec-ptb-160" />
          {/* <FeaturesNav /> */}

          {/* <ServiceSliderOne />
          <FanFact /> */}

        </main>
      </LayoutFluid>
    </Fragment>
  );
};

export default AboutPage;
