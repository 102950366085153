import React from "react";

import FormikForm from "./Formik";
import ApiService from '../../utils/api/api'
import { toast } from 'react-toastify';
import Recaptcha from 'react-google-invisible-recaptcha';

class ContactForm extends React.Component {

  state = {
    values: null,
    setSubmitting: null,
    resetForm: null,
  }


  render() {

    this.refRecaptcha = React.createRef();

    let values = {
      nome: "",
      telefone: "",
      email: "",
      assunto: "",
      mensagem: "",
    };
    return (
      <>
        {/* <FormikForm values={values} handleSubmit={this.handleFormSubmit} /> */}
        <FormikForm values={values} handleSubmit={this.sendMessage} />
        <Recaptcha
          ref={this.refRecaptcha}
          sitekey="6LfB864gAAAAACIxgLIbVLuggDYiz9RkN5FXA1Hb"
          locale='pt-br'
          //badge='bottomleft'
          onResolved={this.onResolved}
        />

      </>

    )
  }

  sendMessage = (values, setSubmitting, resetForm) => {
    this.setState({ values: values, setSubmitting: setSubmitting, resetForm: resetForm });
    this.refRecaptcha.current.callbacks.execute();
  }

  onResolved = (token) => {
    this.handleFormSubmit();
  }

  handleFormSubmit = () => {

    ApiService.EnviaMensagemContato(this.state.values).then((response) => {
      this.state.setSubmitting(false);
      console.log(response)
      if (response.data.status === 0) {
        toast.success("Mensagem enviada. Obrigado por entrar em contato.")

        this.state.resetForm(); // limpa o form
        this.refRecaptcha.current.callbacks.reset(); // limpa o captcha para uma próxima validação
      }
      else toast.error(response.data.msg);

    }).catch((e) => {
      this.state.setSubmitting(false);
      toast.error(e.message);
    });

  };
}

export default ContactForm;
