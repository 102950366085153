import React, { Fragment } from "react";
import { isMobile } from 'react-device-detect';
import { Container, Row, Col } from "reactstrap";

const SectionComanda = ({ sectionSpace }) => {

  return (
    <Fragment>
      <section
        id="features-section"
        className={`features-section ${sectionSpace} clearfix`}
      >
        <Container>
          <div className="feature-item" >
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="5">
                <div className="feature-image-1">
                  <span className="circle-image" >
                    <img
                      src="assets/images/features/animation/gw-comanda-1.1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="phone-image"
                    data-aos="zoom-out"
                    data-aos-delay="600"
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda-1.8.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="comment-image-1"
                    data-aos="fade-left"
                    data-aos-delay="800"
                    style={{ top: 20, left: 30 }}
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda-1.9.png"
                      alt="image_not_found"
                    />
                  </span>
                  {/* <span
                    className="comment-image-2"
                    data-aos="fade-left"
                    data-aos-delay="1000"
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda1.1.png"
                      alt="image_not_found"
                    />
                  </span> */}
                </div>
              </Col>

              <Col lg="6" md="8">
                <div className="feature-content">
                  <div className="section-title mb-30">
                    <span className="sub-title mb-15">Lançamento</span>
                    <h2 className="title-text mb-0">
                      Encontre rápido o produto {" "}
                      {/* <span>asdasd</span> */}
                    </h2>
                  </div>

                  <p className="mb-30">Não é legal quando um garçom esta te atendendo e ele parece perdido,
                    tentando encontrar o produto que você pediu para poder lançar.</p>
                  <p className="mb-30">
                    Por isso, nosso app, contém diversas formas de agilizar o atendimento:
                  </p>

                  <div className="info-list ul-li-block mb-45 clearfix">
                    <ul className="clearfix">
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Categorias e produtos com cores custumizadas, para rápida identificação do produto.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Campo de busca sempre visível.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Busque pela descrição, código ou até mesmo utilizando a câmera para ler o QRCode do produto.
                        </span>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="btns-group ul-li clearfix">
                    <ul className="clearfix">
                      <li>
                        <Link
                          to="/features-app-landing-creative"
                          className="custom-btn"
                        >
                          See All Features
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/features-saas-landing-creative"
                          className="custom-btn-underline"
                        >
                          See All Features
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </Col>
            </Row>
          </div>

          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="8">
                <div className="feature-content">

                  <div className="section-title mb-30">
                    <span className="sub-title mb-15">
                      Pedido
                    </span>
                    <h2 className="title-text mb-0">
                      Evite erros
                      {/* <span>featureDataTwo.span</span> */}
                    </h2>
                  </div>


                  <p className="mb-30">
                    Determine regras para cada produto.
                  </p>
                  <p className="mb-30">
                    Faça com que o garçom, apenas siga o roteiro que a própria tela
                    de lançamento irá lhe pedir que cumpra.
                  </p>

                  <div className="info-list ul-li-block mb-45 clearfix">
                    <ul className="clearfix">
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Determine o que é obrigatório. Ex. ponto da carne, ou a seleção de algum item
                          necessário para compor aquele produto.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Configure todo tipo de produto. Combos, pizzas, montagem, obrigatórios, adicionais, inteiros e etc.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Adicione observação para cada item que for necessário.
                        </span>
                      </li>
                    </ul>
                  </div>
                  {/* 
                  <Link to="/pricing-page" className="custom-btn-underline m-0">
                    Start 30 Days Free Trail
                  </Link> */}
                </div>
              </Col>

              <Col lg="5" md="5">
                <div className="feature-image-1" >
                  <span className="circle-image">
                    <img
                      src="assets/images/features/animation/gw-comanda-1.1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="phone-image"
                    data-aos="zoom-in"
                    data-aos-delay="800"
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda-1.2.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="comment-image-1"
                    data-aos="fade-right"
                    data-aos-delay="1100"
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="comment-image-2"
                    data-aos="fade-right"
                    data-aos-delay="1300"
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda1.1.png"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </div>


          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="5">
                <div className="feature-image-1">
                  <span className="circle-image">
                    <img
                      src="assets/images/features/animation/gw-comanda-1.1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="phone-image"
                    data-aos="zoom-out"
                    data-aos-delay="600"
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda-1.3.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="comment-image-1"
                    data-aos="fade-left"
                    data-aos-delay="800"
                    style={{ left: -80, top: 31 }}
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda-1.4.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="comment-image-2"
                    data-aos="fade-left"
                    data-aos-delay="1000"
                    style={{ left: 100 }}
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda-1.5.png"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>

              <Col lg="6" md="8">
                <div className="feature-content">
                  <div className="section-title mb-30">
                    <span className="sub-title mb-15">Conferência</span>
                    <h2 className="title-text mb-0">
                      Conferência do Pedido {" "}
                      {/* <span>asdasd</span> */}
                    </h2>
                  </div>

                  <p className="mb-30">
                    Na tela de conferência, visualize todo o detalhamento da mesa.
                    Nela, é possível realizar alterações no pedido caso seja necessário.
                  </p>

                  <div className="info-list ul-li-block mb-45 clearfix">
                    <ul className="clearfix">
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Transfira itens de uma mesa para outra.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Exclua itens que tenham sido lançado errado, ou, que o cliente
                          tenha desistido.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          É possível imprimir a conta em uma impressora bluetooth,
                          impressora TCP/IP, e no caso dos POS, diretamente na impressora integrada.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="feature-item" style={{ paddingBottom: 120 }}>
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="8">
                <div className="feature-content">

                  <div className="section-title mb-30">
                    <span className="sub-title mb-15">
                      Mapa
                    </span>
                    <h2 className="title-text mb-0">
                      Mapa de Mesas {" "}
                      {/* <span>mensagens</span> */}
                    </h2>
                  </div>


                  <p className="mb-30">
                    Tenha um panorama geral de todas as mesas.
                  </p>

                  <div className="info-list ul-li-block mb-45 clearfix">
                    <ul className="clearfix">
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Opção para o garçom filtrar e visualizar apenas as mesas que ele esta atendendo.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          As cores determinam os status da mesa, sendo, branco (consumindo), verde (fechada, conta impressa),
                          azul (agrupada) e preto (bloqueada).
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Visualize detalhes do lançamento, saiba quem lançou e quem fechou a conta.
                        </span>
                      </li>
                    </ul>
                  </div>

                  {/* <Link to="/pricing-page" className="custom-btn-underline m-0">
                    Start 30 Days Free Trail
                  </Link> */}
                </div>
              </Col>

              <Col lg="5" md="5">
                <div className="feature-image-1" >
                  <span className="circle-image">
                    <img
                      src="assets/images/features/animation/gw-comanda-1.1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="phone-image"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda-1.6.png"
                      alt="image_not_found"
                    />
                  </span>
                  {/* <span
                    className="comment-image-1"
                    data-aos="fade-right"
                    data-aos-delay="400"
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda-1.4.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="comment-image-2"
                    data-aos="fade-right"
                    data-aos-delay="600"
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda-1.5.png"
                      alt="image_not_found"
                    />
                  </span> */}
                </div>
              </Col>
            </Row>

          </div>

          <div className='text-center'>
            <h2 className='title-text mb-30'>Ficou alguma dúvida? </h2>
            <a href={`https://${isMobile ? 'api' : 'web'}.whatsapp.com/send?phone=+5511971421566&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Gar%C3%A7om%20Web`} target='_blank' rel="noopener noreferrer" className="custom-btn-underline m-0" style={{ textTransform: 'none' }}>
              Falar com um consultor
            </a>
          </div>
        </Container>
      </section>
    </Fragment>
  );
};

export default SectionComanda;
