import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import TitleSection from "../Titlesection/TitleSection";

const About = ({ sectionSpace }) => {
  return (
    <Fragment>
      <section
        id="about-section"
        className={`about-section ${sectionSpace} clearfix`}
        style={{ paddingBottom: 60 }}
      >
        <Container>
          <Row className="justify-content-lg-between justify-content-md-center">
            <Col lg="5" md="8" xs="12">
              <div className="about-image">
                <span className="item-image">
                  <img
                    src="assets/images/about/img-2.png"
                    alt="image_not_found"
                  />
                </span>
                {/* <VideoModal /> */}
              </div>
            </Col>

            <Col lg="6" md="8" xs="12">
              <div className="about-content">
                <h2 className="title-text mb-45">
                  A Garçom Web é uma empresa de <span>Automação Comercial</span>
                </h2>
                <p className="mb-30">Integradora de solução para o comércio no geral. Com profissionais experientes na área de Automação Comercial, em 2002 nasceu a Garçom Web. Uma empresa inovadora que busca soluções especiais para seus clientes, e conta com uma equipe especializada sempre pronta para atendê-los.</p>
                <blockquote className="blockquote" style={{
                  margin: "50px 30px",
                  padding: "30px 40px",
                  borderLeft: "6px solid #138afd"
                }}>
                  <p className="mb-0">
                    “Ser reconhecido como o melhor Sistema, para o setor da Automação Comercial, pelos operadores:
                    Garçons, Gerentes e Gestores da nossa área de atuação e por todos que trabalham na Garçom Web.”
                  </p>
                </blockquote>
                {/* <p className="mb-60">Nossa visão é ser referência na excelência dos serviços prestados e oferecidos. Tornar-se lider no setor de Automação Comercial, destacando-se por sua qualidade de trabalho, equipe capacitada e comprometida na satisfação 100% de seus clientes.</p> */}
                <div className="hero-content">
                  {/* <small className="hero-signature mb-30">
                    <img src={img} alt="signature_not_found" />
                  </small> */}
                  <span className="hero-name text-right">
                    João Soares <small className="hero-title">- CEO Garçom Web </small>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <section className='features-section clearfix' style={{ paddingTop: 100 }}>
            <Row className="justify-content-center">
              <Col sm="12" lg="12" md="8">
                <TitleSection spacer="mb-100" textAlign="text-center">
                  <span className="sub-title mb-15">Missão</span>
                  <h2 className="title-text mb-30">Temos uma missão</h2>
                  <p className="paragraph-text mb-30">
                    Servir o Chef (Dono do Negócio da Gastronomia), prestando assistência humanizada e eficiente,
                    em conformidade com os princípios do Melhor Serviço, e contribuir para o aprimoramento da gestão do Seu Negócio.
                  </p>
                </TitleSection>
              </Col>
            </Row>


            <div className="feature-item">
              <Row className="justify-content-lg-between justify-content-md-center">
                <Col lg="6" md="8">
                  <div className="feature-content">

                    <div className="section-title mb-30">
                      <span className="sub-title mb-15" style={{ paddingLeft: 0, borderLeft: 0 }}>
                        Valores
                      </span>
                      <h2 className="title-text mb-30">
                        Honestidade
                        {/* <span>make your life easier then others</span> */}
                      </h2>
                      <p className='mb-30'>
                        A Garçom Web é regida pela verdade e transparência em seus atos e declarações,
                        de forma que se estabeleça uma relação de confiança com seus funcionários, clientes e parceiros.
                      </p>
                      <h2 className="title-text mb-30">
                        Qualidade
                        {/* <span>make your life easier then others</span> */}
                      </h2>
                      <p className='mb-30'>
                        Buscar sempre as melhores e mais seguras. práticas em sistema e gestão.
                      </p>
                      <h2 className="title-text mb-30">
                        Justiça
                        {/* <span>make your life easier then others</span> */}
                      </h2>
                      <p className='mb-30'>
                        A Garçom Web preza pela igualdade, respeita os direitos dos seus funcionários e atua de
                        forma responsável junto à seus clientes e parceiros.
                      </p>
                      <h2 className="title-text mb-30">
                        Respeito
                        {/* <span>make your life easier then others</span> */}
                      </h2>
                      <p className='mb-30'>
                        Todos que trabalham na Garçom Web respeitam o próximo, independente de crença, raça,
                        sexo, posição social, idade, cultura e condição. econômico-financeira.
                      </p>
                      <h2 className="title-text mb-30">
                        Diligência
                        {/* <span>make your life easier then others</span> */}
                      </h2>
                      <p className='mb-30'>
                        Zelar e executar com cuidado todas as tarefas.
                      </p>
                      <h2 className="title-text mb-30">
                        Competência
                        {/* <span>make your life easier then others</span> */}
                      </h2>
                      <p className='mb-30'>
                        A Garçom Web investe na capacitação e promove a disseminação do conhecimento,
                        desenvolvendo em seus profissionais habilidades e atitudes que resultem em uma atuação diferenciada.
                      </p>
                    </div>




                    {/* <div className="info-list ul-li-block mb-45 clearfix">
                      <ul className="clearfix">
                        <li>
                          <small className="icon">
                            <i className="flaticon-checked"></i>
                          </small>
                          <span className="info-text">
                            Satisfação do cliente.
                          </span>
                        </li>
                        <li>
                          <small className="icon">
                            <i className="flaticon-checked"></i>
                          </small>
                          <span className="info-text">
                            Ética.
                          </span>
                        </li>
                        <li>
                          <small className="icon">
                            <i className="flaticon-checked"></i>
                          </small>
                          <span className="info-text">
                            Buscar sempre o melhor para todos.
                          </span>
                        </li>
                        <li>
                          <small className="icon">
                            <i className="flaticon-checked"></i>
                          </small>
                          <span className="info-text">
                            Valorização e respeito a pessoa.
                          </span>
                        </li>
                        <li>
                          <small className="icon">
                            <i className="flaticon-checked"></i>
                          </small>
                          <span className="info-text">
                            Relacionamento com clientes e colaboradores deve ser transparente e baseado na responsabilidade e confiança entre as partes.
                          </span>
                        </li>
                        <li>
                          <small className="icon">
                            <i className="flaticon-checked"></i>
                          </small>
                          <span className="info-text">
                            Acreditar sempre que somos parte de um objetivo comum.
                          </span>
                        </li>
                        <li>
                          <small className="icon">
                            <i className="flaticon-checked"></i>
                          </small>
                          <span className="info-text">
                            Trabalhar para desenvolver um Estado democrático de sucesso e oportunidades.
                          </span>
                        </li>
                        <li>
                          <small className="icon">
                            <i className="flaticon-checked"></i>
                          </small>
                          <span className="info-text">
                            Unir esforços na valorização do indivíduo na sociedade.
                          </span>
                        </li>
                        <li>
                          <small className="icon">
                            <i className="flaticon-checked"></i>
                          </small>
                          <span className="info-text">
                            Buscar sempre o melhor para todos.
                          </span>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </Col>
                <Col lg="5" md="6" xs="12">
                  <div className="feature-content float-right" style={{ paddingTop: 160 }}>
                    <span
                      className="big-image"
                      data-aos="zoom-out"
                      data-aos-delay="200"
                    >
                      <img
                        src="assets/images/mobile-banner/img-1.png"
                        alt="image_not_found"
                      />
                    </span>
                  </div>
                </Col>
              </Row>
            </div>

          </section>
        </Container>
      </section>

    </Fragment >
  );
};

export default About;
