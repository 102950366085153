import React from "react";
//import { Link } from "react-router-dom";
import TitleSection from "../Titlesection/TitleSection";
import { Container, Row, Col } from "reactstrap";

// import titleOne from "../../data/WorkingProgress/working-progress-two.json";
// const [{ title, subTitle, text }] = titleOne;


const HowItWorksGwLive = ({ sectionSpace }) => {


  return (
    <section
      id="working-process-section"
      className={`working-process-section ${sectionSpace} clearfix`}
      style={{ paddingTop: 60 }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col sm="12" lg="6" md="8">
            <TitleSection spacer="mb-100" textAlign="text-center">
              <span className="sub-title mb-15">Como funciona</span>
              <h2 className="title-text mb-30">Sua empresa em tempo real </h2>
              <p className="paragraph-text mb-0">
                Fique por dentro do seu faturamento, ticket médio, produtos vendidos, mesas em aberto,
                delivery em aberto, vendas online (App de Delivery), cancelamentos, transferências, descontos,
                balcão em espera e muito mais.
              </p>
            </TitleSection>
          </Col>
        </Row>

        <Row className="justify-content-lg-start justify-content-md-center">
          <Col lg="4" md="8" xs="12">
            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/chart.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Gráficos</h3>
                <p className="mb-0">
                  Visualize e interaja com gráficos que detalham os mais importantes dados em tempo real
                  da sua empresa.
                </p>
              </div>
            </div>

            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/faturamento.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Faturamento</h3>
                <p className="mb-0">
                  Compare o faturamento entre as suas empresas, e visualize quais produtos tem
                  gerado mais receita.
                </p>
              </div>
            </div>
          </Col>

          <Col lg="4" md="8" xs="12">
            <div className="process-phone-image" >
              <span
                className="bg-image"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <img
                  src="assets/images/features/animation/6-img-1.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="phone-image"
                data-aos="zoom-in"
                data-aos-delay="700"
              >
                <img
                  src="assets/images/features/animation/gw-Live.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="shape-image-1"
                data-aos="fade-right"
                data-aos-delay="900"
              >
                <img
                  src="assets/images/features/animation/6-img-2.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="shape-image-2"
                data-aos="fade-left"
                data-aos-delay="1100"
              >
                <img
                  src="assets/images/features/animation/6-img-4.png"
                  alt="image_not_found"
                />
              </span>
            </div>
          </Col>

          <Col lg="4" md="8" xs="12">
            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/detalhes.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Detalhes</h3>
                <p className="mb-0">
                  Todos os gráficos possuem detalhamento. Por Exemplo, Ao selecionar uma mesa, é possível ver cada produto vendido nela.
                </p>
              </div>
            </div>

            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/cancelamento.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Cancelamentos</h3>
                <p className="mb-0">
                  Não fique sem informação. Saiba quais mesas e/ou produtos foram cancelados, e quem cancelou.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <div className="seemore-btn text-center">
          {/* <Link to="#" className="custom-btn-underline">
            see all features
          </Link> */}
        </div>
      </Container>
    </section>
  );
};

export default HowItWorksGwLive;
