import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const Navigation = ({ activeClass }) => {

  let menuActive = "home";
  const url = window.location.href;
  if (url.includes("garcom-web-")) menuActive = "produtos";

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -120;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }

  return (
    <Fragment>
      <nav className="main-menubar ul-li-right clearfix">
        <ul className="clearfix">
          {/* <li className={`${activeClass ? activeClass : null}`} > */}
          <li className={`${menuActive === "home" ? activeClass : null}`} >
            <Link to="/">home</Link>
            {/* <ul className="sub-menu">
              <li
                className={`menu-item-has-child ${activeClass ? activeClass : null
                  }`}
              >
                <Link to="#!">mobile app</Link>
                <ul className="sub-menu">
                  <li className={activeClass ? activeClass : null}>
                    <Link to="/">App Landing Creative</Link>
                  </li>
                  <li>
                    <Link to="app-landing-elegant">App Landing Elegant</Link>
                  </li>
                  <li>
                    <Link to="app-landing-modern">App Landing Modern</Link>
                  </li>
                  <li>
                    <Link to="app-landing-minimal">App Landing Minimal</Link>
                  </li>
                  <li>
                    <Link to="app-landing-exclusive">
                      App Landing Exclusive
                    </Link>
                  </li>
                  <li>
                    <Link to="app-landing-classic">App Landing Classic</Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item-has-child">
                <Link to="#!">sass landing page</Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="saas-landing-creative">
                      Saas Landing Creative
                    </Link>
                  </li>
                  <li>
                    <Link to="saas-landing-elegant">Saas Landing Elegant</Link>
                  </li>
                  <li>
                    <Link to="saas-landing-exclusive">
                      Saas Landing Exclusive
                    </Link>
                  </li>
                  <li>
                    <Link to="saas-landing-modern">Saas Landing Modern</Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item-has-child">
                <Link to="#!">app store</Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="app-store-creative">App Store Creative</Link>
                  </li>
                  <li>
                    <Link to="app-store-elegant">App Store Elegant</Link>
                  </li>
                </ul>
              </li>
            </ul> */}
          </li>

          <li >
            <Link to="quem-somos">Quem Somos</Link>
          </li>
          {/* <li >
            <Link to="/home">Produtos</Link>
          </li> */}

          {/* <li className={`menu-item-has-child`}>
            <Link to="#">Quem Somos</Link>
            <ul className="sub-menu">
              <li>
                <Link to="/quem-somos">
                  Quem Somos
                </Link>
              </li>
              <li>
                <Link to="/portifolio">
                  Portifólio
                </Link>
              </li>
              <li>
                <Link to="/suporte">
                  Suporte
                </Link>
              </li>
              <li>
                <Link to="/trabalhe-conosco">
                  Trabalhe Conosco
                </Link>
              </li>
              <li>
                <Link to="/faq">
                  FAQ
                </Link>
              </li>

            </ul>
          </li> */}


          <li className={`${menuActive === "produtos" ? activeClass : null} menu-item-has-child`}>
            <Link to="#">Produtos</Link>
            <ul className="sub-menu">

              <li>
                <HashLink smooth to="/home#balcao" scroll={el => scrollWithOffset(el)} style={{ color: url.includes("garcom-web-balcao") ? '#138afd' : '' }}>
                  Garçom Web Balcão
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/home#mesa" scroll={el => scrollWithOffset(el)} style={{ color: url.includes("garcom-web-mesa") ? '#138afd' : '' }}>
                  Garçom Web Mesa
                </HashLink>
                {/* <Link to="/garcom-web-mesa" style={{ color: url.includes("garcom-web-mesa") ? '#138afd' : '' }}>
                  Garçom Web Mesa
                </Link> */}
              </li>
              <li>
                <HashLink smooth to="/home#mesa" scroll={el => scrollWithOffset(el)} style={{ color: url.includes("garcom-web-cartao") ? '#138afd' : '' }}>
                  Garçom Web Cartão
                </HashLink>
                {/* <Link to="/garcom-web-cartao" style={{ color: url.includes("garcom-web-cartao") ? '#138afd' : '' }}>
                  Garçom Web Cartão
                </Link> */}
              </li>
              <li>
                <HashLink smooth to="/home#delivery" scroll={el => scrollWithOffset(el)} style={{ color: url.includes("garcom-web-delivery") ? '#138afd' : '' }}>
                  Garçom Web Delivery
                </HashLink>
                {/* <Link to="/garcom-web-delivery" style={{ color: url.includes("garcom-web-delivery") ? '#138afd' : '' }}>
                  Garçom Web Delivery
                </Link> */}
              </li>

              <li>
                <Link to="/garcom-web-comanda" style={{ color: url.includes("garcom-web-comanda") ? '#138afd' : '' }}>
                  Garçom Web Comanda
                </Link>
              </li>
              <li>
                <Link to="/garcom-web-fast" style={{ color: url.includes("garcom-web-fast") ? '#138afd' : '' }}>
                  Garçom Web Fast
                </Link>
              </li>
              <li>
                <Link to="/garcom-web-live" style={{ color: url.includes("garcom-web-live") ? '#138afd' : '' }}>
                  Garçom Web Live
                </Link>
              </li>
              <li>
                <Link to="/garcom-web-link" style={{ color: url.includes("garcom-web-link") ? '#138afd' : '' }}>
                  Garçom Web Link
                </Link>
              </li>
              <li>
                <Link to="/" style={{ color: url.includes("garcom-web-office") ? '#138afd' : '' }}>
                  Garçom Web Office
                </Link>
              </li>
              <li>
                <Link to="/" style={{ color: url.includes("garcom-web-micro-terminal") ? '#138afd' : '' }}>
                  Garçom Web MT
                </Link>
              </li>
            </ul>
          </li>

          {/* <li >
            <Link to="/revendas">Revendas</Link>
          </li> */}

          <li>
            <Link to="/contato">Contato</Link>
          </li>
        </ul>
      </nav>
    </Fragment >
  );
};

export default Navigation;
