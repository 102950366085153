import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
//import { Link } from "react-router-dom";

const SectionModulos = ({ sectionSpace }) => {

  return (
    <Fragment>
      <section
        id="features-section"
        className={`features-section ${sectionSpace} clearfix`}
      >
        <Container>
          <div className="feature-item" id="mesa">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="6" xs="12">
                <div className="feature-image-10 float-left">
                  <span
                    className="big-image"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <img
                      src="assets/images/features/animation/mesa.png"
                      alt="image_not_found"
                    />
                  </span>
                  {/* <span
                    className="small-image-1"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <img
                      src="assets/images/features/animation/mesa-1.png"
                      alt="image_not_found"
                    />
                  </span> */}
                  <span
                    className="small-image-2"
                    data-aos="zoom-in"
                    data-aos-delay="1000"
                    style={{
                      bottom: '-10px',
                      right: '-40px'
                    }}
                  >
                    <img
                      src="assets/images/features/animation/mesa1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="shape-1"
                    style={{ zIndex: 0 }}
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  >
                    <img
                      src="assets/images/features/animation/6-img-3.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="shape-2"
                    data-aos="fade-right"
                    data-aos-delay="750"
                  >
                    <img
                      src="assets/images/features/animation/6-img-4.png"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
              <Col lg="6" md="8">
                <div className="feature-content">
                  <div className="section-title mb-30">
                    <span className="sub-title mb-15">Garçom Web Mesa</span>
                    <h2 className="title-text mb-0">
                      Controle de mesas para
                      <span>bares, restaurantes, padarias... </span>
                    </h2>
                  </div>

                  <p className="mb-30">
                    É muito importante atender o cliente com rápidez e eficiência. E foi por isso que o Garçom Web foi
                    desenvolvido com design simples e moderno, portanto, muito fácil de operar. O Garçom Web é voltado para o food service em geral.
                  </p>

                  <div className="info-list ul-li-block mb-45 clearfix">
                    <ul className="clearfix">
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Bar & Restaurante
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Café & Padaria
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Pizzaria & Hamburgueria
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Lanchonete & Doceria
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Balada & Casa de Show
                        </span>
                      </li>

                    </ul>
                  </div>

                  <div className="btns-group ul-li clearfix">
                    <ul className="clearfix">
                      {/* <li>
                        <Link to="/pricing-page" className="custom-btn">
                          try it free
                        </Link>
                      </li> */}
                      <li>
                        {/* <Link
                          to="/garcom-web-mesa"
                          className="custom-btn-underline"
                        >
                          + Detalhes
                        </Link> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="feature-item" id="delivery">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="8">
                <div className="feature-content">

                  <div className="section-title mb-30">
                    <span className="sub-title mb-15">
                      Garçom Web Delivery
                    </span>
                    <h2 className="title-text mb-0">
                      Gerencie com eficiência
                      <span>seus pedidos de Delivery</span>
                    </h2>
                  </div>

                  <p className="mb-30">
                    O Garçom Web Delivery, torna mais fácil atender seus clientes por delivery,
                    pois conta com inúmeros recursos que auxiliam este atendimento. Veja alguns:
                  </p>

                  <div className="info-list ul-li-block mb-45 clearfix">
                    <ul className="clearfix">
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Integrado ao iFood. Seu pedido feito no iFood é captado pelo nosso sistema que já o lança e dispara os pedidos na produção.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Taxa de Entrega personalizada. Cobre a taxa por: Porcentagem, Valor Fixo, Quilometragem ou Zoneamento.
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Mapa de Entrega. O sistema te avisa, se aquele endereço tem atendimento ou não.
                        </span>
                      </li>
                    </ul>
                  </div>

                  {/* <Link to="/garcom-web-delivery" className="custom-btn-underline m-0">
                    + Detalhes
                  </Link> */}
                </div>
              </Col>
              <Col lg="5" md="6" xs="12">
                <div className="feature-image-10 float-right">
                  <span
                    className="big-image"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <img
                      src="assets/images/features/animation/delivery.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="small-image-1"
                    data-aos="zoom-in"
                    data-aos-delay="400"
                  >
                    <img
                      src="assets/images/features/animation/delivery-1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="shape-2"
                    data-aos="fade-up"
                    data-aos-delay="550"
                  >
                    <img
                      src="assets/images/features/animation/6-img-4.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="shape-3"
                    data-aos="zoom-in"
                    data-aos-delay="500"
                  >
                    <img
                      src="assets/images/features/animation/6-img-3.png"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </div>

          <div className="feature-item" style={{ paddingTop: 60 }} id="balcao">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="6" xs="12">
                <div className="feature-image-10 float-left">
                  <span
                    className="big-image"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <img
                      src="assets/images/features/animation/balcao.png"
                      alt="image_not_found"
                    />
                  </span>
                  {/* <span
                    className="small-image-1"
                    data-aos="fade-up"
                    data-aos-delay="400"
                    style={{
                      left: '-100px',
                      top: '16px'
                    }}
                  >
                    <img
                      src="assets/images/features/animation/mesa-1.png"
                      alt="image_not_found"
                    />
                  </span> */}
                  <span
                    className="small-image-2"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    <img
                      src="assets/images/features/animation/balcao-2.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="shape-1"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                    style={{ left: 205, zIndex: 0 }}
                  >
                    <img
                      src="assets/images/banner/shape-4.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="shape-2"
                    data-aos="fade-right"
                    data-aos-delay="750"
                    style={{ zIndex: 2 }}
                  >
                    <img
                      src="assets/images/features/animation/6-img-4.png"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
              <Col lg="6" md="8">
                <div className="feature-content" >
                  <div className="section-title mb-30">
                    <span className="sub-title mb-15">Garçom Web Balcão</span>
                    <h2 className="title-text mb-0">
                      Atendimento {" "}
                      <span>direto ao cliente </span>
                    </h2>
                  </div>

                  <p className="mb-30">O Frente de caixa do Garçom Web, conta com diversos recursos que se adaptam a sua necessidade, e atende diversos tipos de negócio. </p>

                  <div className="info-list ul-li-block mb-45 clearfix">
                    <ul className="clearfix">
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Bar & Restaurante
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Café & Padaria
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Pizzaria & Hamburgueria
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Lanchonete & Doceria
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Food Truck & Quiosques
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Lojas de Conveniência
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Mercadinho & Empório
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="btns-group ul-li clearfix">
                    <ul className="clearfix">
                      {/* <li>
                        <Link to="/pricing-page" className="custom-btn">
                          try it free
                        </Link>
                      </li> */}
                      <li>
                        {/* <Link
                          to="/garcom-web-mesa"
                          className="custom-btn-underline"
                        >
                          + Detalhes
                        </Link> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>



        </Container>
      </section>
    </Fragment>
  );
};

export default SectionModulos;
