import React from "react";
//import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import brandData from "../../data/brand/brand.json";

const SectionClientes = (props) => {
  const params = {
    spaceBetween: 30,
    slidesPerView: 5,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    navigation: false,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 5,
      },
      1500: {
        slidesPerView: 6,
      },
    },
  };

  return (
    <div id="client-section" className="client-section  pb-0 clearfix">
      <div id="client-carousel" className="client-carousel">
        <p className="title-paragraph-text">
          Junte-se a essas e outras empresas que{" "}
          <span> já usam nossas soluções</span>
        </p>
        <div className="container">
          <Swiper {...params}>
            {/* <!-- client-section - start
		================================================== --> */}

            {brandData.map(function (slide, index) {
              return (
                <SwiperSlide key={index} className="item">
                  <a className="client-logo" href={slide.url} target={"_blank"} rel="noopener noreferrer" style={{ height: 100 }}>
                    <span className="before-image">
                      <img src={slide.img} alt={slide.alt} height={100} style={{ height: 100, objectFit: 'cover' }} />
                    </span>
                    <span className="after-image">
                      <img src={slide.img} alt={slide.alt} height={100} style={{ height: 100, objectFit: 'cover' }} />
                    </span>
                  </a>
                </SwiperSlide>
              );
            })}

            {/* <!-- client-section - end
		================================================== --> */}
          </Swiper>
          {/* <div className="review-btn text-center" style={{ paddingTop: 60 }}>
            <Link to="/review-page" className="custom-btn-underline">
              Portifólio
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SectionClientes;
