import { VideoModal } from 'components/Modal';
import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use(Pagination);

const BannerHome = () => {
  // const params = {
  //   spaceBetween: 0,
  //   slidesPerView: 1,
  //   //pagination: { clickable: true },
  //   loop: true,
  //   autoplay: {
  //     delay: 1000,
  //     disableOnInteraction: false,
  //   },
  //   //autoplay: true,
  //   // breakpoints: {
  //   //   0: {
  //   //     slidesPerView: 1,
  //   //   },
  //   //   600: {
  //   //     slidesPerView: 1,
  //   //   },

  //   //   1200: {
  //   //     slidesPerView: 1,
  //   //   },
  //   // },
  //   navigation: {
  //     nextEl: ".swiper-button-next",
  //     prevEl: ".swiper-button-prev",
  //   },
  // };

  return (
    <Fragment>

      <section id="slider-section" className="slider-section clearfix">
        {/* <Swiper
          {...params}
          id="appstore-main-carousel"
          className="appstore-main-carousel"
          style={{ background: "#FFF", paddingTop: 0 }}
        >
          <SwiperSlide className="item"> */}
        <section id="banner-section" className="banner-section clearfix">
          <div className="sass-banner-4 clearfix" style={{ paddingBottom: 20 }}>

            <Container>
              <Row className="justify-content-lg-between justify-content-md-center">
                <Col lg="7" md="8" xs="12">
                  {/* <!-- show on mobile device - start --> */}
                  <div className="mobile-banner-image d-none">
                    <img
                      src="assets/images/mobile-banner/gw-office.png"
                      alt="image_not_found"
                    />
                  </div>

                  {/* <!-- show on mobile device - end --> */}
                  <div className="banner-content">
                    <h1 className="title-text mb-30">
                      Garçom Web, {" "}
                      <span>Servindo Resultados</span>
                    </h1>
                    <p>
                      Não importa quão grande ou pequeno seja o seu empreendimento.
                      <span>Nós temos a Solução certa para a gestão do seu negócio.</span>
                    </p>
                    <div className="btns-group ul-li clearfix">
                      <ul className="clearfix">
                        <li>
                          {/* <Link to="/garcom-web-office" className="custom-btn">
                            Saiba Mais
                          </Link> */}
                        </li>

                        {/* <li>
                        <Link
                          to="/garcom-web-mesa"
                          className="custom-btn-underline  mb-0"
                        >
                          + Detalhes
                        </Link>
                        <p className="info-text mb-0">
                              asdasd
                        </p>
                      </li> */}
                      </ul>
                    </div>
                  </div>
                </Col>

                <Col lg="5" xs="12">
                  <div className="banner-item-image">
                    <span
                      className="laptop-image"
                      data-aos="fade-left"
                      data-aos-delay="100"
                    >
                      <img
                        src="assets/images/banner/gw-office.png"
                        alt="image_not_found"
                      />
                    </span>
                    <span className="bg-image">
                      <img
                        src="assets/images/banner/shape-3.png"
                        alt="image_not_found"
                      />
                    </span>
                    <span
                      className="shape-image"
                      data-aos="zoom-in"
                      data-aos-delay="300"
                    >
                      <img
                        src="assets/images/banner/shape-4.png"
                        alt="image_not_found"
                      />
                    </span>
                    <VideoModal videoId="tl3RjjQUzMU" />
                    {/* <a
                    className="popup-video"
                    href="https://youtu.be/pmm-1T9Av-g"
                    data-aos="zoom-in"
                    data-aos-delay="900"
                  >
                    <i className="uil uil-play"></i>
                  </a> */}
                  </div>
                </Col>
              </Row>
            </Container>

            <span className="shape-1">
              <img src="assets/images/shapes/cross-2.png" alt="image_not_found" />
            </span>
            <span className="shape-2">
              <img
                src="assets/images/shapes/flow-1-2.png"
                alt="image_not_found"
              />
            </span>
            <span className="shape-3">
              <img
                src="assets/images/shapes/circle-half-2.png"
                alt="image_not_found"
              />
            </span>
            <span className="shape-4">
              <img
                src="assets/images/shapes/circle-half-2.png"
                alt="image_not_found"
              />
            </span>
            <span className="shape-5">
              <img src="assets/images/shapes/box-2.png" alt="image_not_found" />
            </span>
            <span className="shape-6">
              <img
                src="assets/images/shapes/flow-2-2.png"
                alt="image_not_found"
              />
            </span>
          </div>
        </section>
        {/*</SwiperSlide>

           <SwiperSlide className="item" >
            <BannerSix />
          </SwiperSlide> 

        </Swiper>*/}
        {/* <!-- If we need navigation buttons --> */}
        {/* <div className="custom-pagination">
          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </div> */}
      </section>


    </Fragment>
  );
};

export default BannerHome;
