import React, { Fragment } from "react";

import ContactForm from "../../components/Formik";

class Map extends React.Component {
  render() {
    return (
      <Fragment>
        <section id="map-section" className={`map-section clearfix`}>
          <iframe
            className="google-map"
            title="google-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.0961688386806!2d-46.684521807185654!3d-23.564989318753135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce57781ee7f055%3A0x722443f9e1408b3b!2sR.%20C%C3%B4nego%20Eug%C3%AAnio%20Leite%2C%20433%20-%20Pinheiros%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2005414-010%2C%20Brazil!5e0!3m2!1sen!2sbd!4v1655760597516!5m2!1sen!2sbd"
            allowFullScreen
            referrerPolicy="no-referrer-when-downgrade"
            loading="lazy"
          ></iframe>
          <ContactForm />
        </section>
      </Fragment>
    );
  }
}

export default Map;
