import React from "react";
import TitleSection from "../Titlesection/TitleSection";
import { Container, Row, Col } from "reactstrap";


const workingProgressOne = ({ sectionSpace }) => {
  return (
    <section
      id="working-process-section"
      className={`working-process-section ${sectionSpace} clearfix`}
    //style={{ paddingTop: 0 }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col sm="12" lg="6" md="8">
            <TitleSection spacer="mb-100" textAlign="text-center">
              <h2 className="title-text mb-30">Como funciona o sistema{" "}<span>Garçom Web</span></h2>
              <p className="paragraph-text mb-0">Desde a instalação até a geração dos relatórios, todo o processo<span>é acompanhado do nosso suporte e treinamento</span></p>
            </TitleSection>
          </Col>
        </Row>

        <div className="process-steps-list ul-li">
          <ul className="clearfix">
            <li>
              <span className="item-icon">
                <img
                  src="assets/fonts/svg/outline/Interaction.svg"
                  alt="icon_not_found"
                />
              </span>
              <h3 className="item-title mb-0">Instalação &amp; Treinamento</h3>
            </li>
            <li>
              <span className="item-icon">
                <img
                  src="assets/fonts/svg/outline/Development.svg"
                  alt="icon_not_found"
                />
              </span>
              <h3 className="item-title mb-0">Lançamentos</h3>
            </li>
            <li>
              <span className="item-icon">
                <img
                  src="assets/fonts/svg/outline/Pie_Chart.svg"
                  alt="icon_not_found"
                />
              </span>
              <h3 className="item-title mb-0">Conciliação</h3>
            </li>
            <li>
              <span className="item-icon">
                <img
                  src="assets/fonts/svg/outline/Launch.svg"
                  alt="icon_not_found"
                />
              </span>
              <h3 className="item-title mb-0">Resultados</h3>
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
};

export default workingProgressOne;
