import React, { Fragment, useRef, useEffect } from "react";

import { Container, Row, Col } from "reactstrap";
import Parallax from "parallax-js";
//import { Link } from 'react-router-dom';

const BannerComanda = ({ sectionSpace, comanda = false, fast = false, live = false }) => {
  const scenElement = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(scenElement.current, {
      relativeInput: true,
    });
    parallaxInstance.enable();
    return () => parallaxInstance.disable();
  }, []);

  return (
    <Fragment>
      <section
        id="banner-section"
        className={`banner-section ${sectionSpace} clearfix`}
      >
        <div className="mobile-app-banner-6">
          <Container>
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="7" md="8" xs="12">
                {/* <!-- show on mobile device - start --> */}
                <div className="mobile-banner-image d-none">
                  {fast &&
                    <img
                      src={`assets/images/features/animation/gw-fast.png`}
                      alt="image_not_found"
                    />
                  }
                  {comanda &&
                    <img
                      src={`assets/images/features/animation/gw-comanda.png`}
                      alt="image_not_found"
                    />
                  }
                  {live &&
                    <img
                      src={`assets/images/features/animation/gw-live.png`}
                      alt="image_not_found"
                    />
                  }
                </div>
                {/* <!-- show on mobile device - end --> */}
                <div className="banner-content">
                  {/* <span style={{
                    padding: '5px', paddingLeft: '20px', borderLeft: '3px solid #f6716f', fontSize: '16px',
                    color: '#f6716f', fontWeight: 500, textTransform: 'capitalize'
                  }}>
                    Garçom Web Comanda
                  </span> */}
                  <h2>
                    Garçom Web {" "}
                    <span>{fast ? 'Fast' : comanda ? 'Comanda' : 'Live'}</span>
                  </h2>
                  <p>

                    {!live &&
                      `O Aplicativo Garçom Web ${fast ? 'Fast' : 'Comanda'}, é o app de comanda eletrônica do sistema Garçom Web. `
                    }
                    {live &&
                      `O Garçom Web Live é um app integrado ao sistema Garçom Web. 
                      Com ele você acompanha em tempo real o que acontece em seu estabelecimento.`
                    }

                    {!live &&
                      <span>
                        {fast
                          ? 'Com ele, a sua comanda eletrônica se torna um caixa independente, muito útil para diversas situações.'
                          : 'Através dele o atendente realiza o pedido do cliente de forma fácil, rápida e muito intuitiva.'}

                      </span>
                    }

                  </p>
                  <div className="btns-group ul-li clearfix">
                    <ul className="clearfix">

                      <li>
                        <a
                          rel="noopener noreferrer"
                          href={live
                            ? 'https://apps.apple.com/br/app/gw-live/id1248013694?platform=iphone'
                            : 'https://apps.apple.com/br/app/gw-comanda/id1532524975?platform=iphone'}
                          target="_blank"
                          className="store-btn bg-default-black"
                        >
                          <span className="icon">
                            <i className="flaticon-apple"></i>
                          </span>
                          <strong className="title-text">
                            <small>disponível na</small>
                            app store
                          </strong>
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          href={live
                            ? 'https://play.google.com/store/apps/details?id=com.gwsis.live.com&hl=pt_BR'
                            : 'https://play.google.com/store/apps/details?id=com.gwsis.gwapp&hl=pt_BR'}
                          target="_blank"
                          className="store-btn bg-default-pink"
                        >
                          <span className="icon">
                            <i className="flaticon-google-play"></i>
                          </span>
                          <strong className="title-text">
                            <small>disponível na</small>
                            google play
                          </strong>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>

              <Col lg="3">
                <div
                  className="banner-image scene clearfix"
                  id="scene"
                  ref={scenElement}
                >
                  <span className="phone-image float-right" data-depth="0.2">
                    <small
                      data-aos="zoom-out"
                      data-aos-duration="500"
                      data-aos-delay="200"
                    >
                      {live &&
                        <img
                          src={`assets/images/features/animation/gw-live-0.png`}
                          alt="image_not_found"
                        />
                      }
                      {!live &&
                        <img
                          src={`assets/images/features/animation/gw-${fast ? 'fast' : 'comanda'}.png`}
                          alt="image_not_found"
                        />
                      }
                    </small>
                  </span>
                  {/* <span className="commentbar-image-3" data-depth="0.5">
                    <small
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      data-aos-delay="1000"
                    >
                      <img
                        src="assets/images/features/animation/gw-comanda-2.png"
                        alt="image_not_found"
                      />
                    </small>
                  </span> */}
                  <span className="commentbar-image-1" data-depth="0.5">
                    <small
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      data-aos-delay="700"
                    >
                      {live &&
                        <img
                          src={`assets/images/features/animation/gw-live-1.png`}
                          alt="image_not_found"
                        />
                      }
                      {!live &&
                        <img
                          src={`assets/images/features/animation/gw-${fast ? 'fast-1' : 'comanda-1'}.png`}
                          alt="image_not_found"
                        />
                      }
                    </small>
                  </span>
                  <span className="commentbar-image-2" data-depth="0.5">
                    <small
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      data-aos-delay="1000"
                    >
                      {/* {live &&
                        <img
                          src={`assets/images/features/animation/gw-live-2.png`}
                          alt="image_not_found"
                        />
                      } */}
                      {!live &&
                        <img
                          src={`assets/images/features/animation/gw-${fast ? 'fast-2' : 'comanda-2'}.png`}
                          alt="image_not_found"
                        />
                      }

                    </small>
                  </span>

                </div>
              </Col>
            </Row>
          </Container>
          <span className="shape-bg-image">
            <img
              src="assets/images/banner/mab-6-img-1.png"
              //src="assets/images/banner/mab-4-img-1.png"
              alt="image_not_found"
            />
          </span>
        </div>
      </section>
    </Fragment>
  );
};

export default BannerComanda;
