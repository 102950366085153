import React, { Fragment } from "react";
import { isMobile } from 'react-device-detect';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { UncontrolledCollapse } from "reactstrap";

const SideBar = ({ sidebarIsOpen, handleSidebar }) => {


  let menuActive = "home";
  const url = window.location.href;
  if (url.includes("garcom-web-")) menuActive = "produtos";
  if (url.includes("quem-somos")) menuActive = "quem-somos";
  if (url.includes("contato")) menuActive = "contato";

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -120;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }


  return (
    <Fragment>
      <div className="sidebar-menu-wrapper">
        <div
          id="sidebar-menu"
          className={`sidebar-menu ${sidebarIsOpen ? "active" : null}`}
        >
          <div className="dismiss text-right mb-60 clearfix">
            <span className="close-btn" onClick={handleSidebar}>
              <i className="flaticon-cancel-music"></i>
            </span>
          </div>






          <ul className="menu-list mb-60 list-unstyled components clearfix">

            <li >
              <Link to="/" style={{ color: menuActive === "home" ? '#138afd' : '' }} onClick={handleSidebar}>
                Home
              </Link>
            </li>
            <li>
              <Link to="quem-somos" style={{ color: url.includes("quem-somos") ? '#138afd' : '' }} onClick={handleSidebar}>
                Quem Somos
              </Link>
            </li>

            <li className={`menu-item-has-child`}>
              <Link to="#?" id="toggler0" style={{ color: menuActive === "produtos" ? '#138afd' : '' }}>
                Produtos
              </Link>

              <UncontrolledCollapse toggler="#toggler0">
                <ul className="sub-menu list-unstyled">
                  <li className={url.includes("garcom-web-balcao") ? "active" : ''}>
                    <HashLink smooth to="/home#balcao" scroll={el => scrollWithOffset(el)} onClick={handleSidebar}>
                      Garçom Web Balcão
                    </HashLink>
                  </li>
                  <li className={url.includes("garcom-web-mesa") ? "active" : ''}>
                    <HashLink smooth to="/home#mesa" scroll={el => scrollWithOffset(el)} onClick={handleSidebar}>
                      Garçom Web Mesa
                    </HashLink>
                  </li>
                  <li className={url.includes("garcom-web-cartao") ? "active" : ''}>
                    <HashLink smooth to="/home#mesa" scroll={el => scrollWithOffset(el)} onClick={handleSidebar}>
                      Garçom Web Cartão
                    </HashLink>
                  </li>
                  <li className={url.includes("garcom-web-delivery") ? "active" : ''}>
                    <HashLink smooth to="/home#delivery" scroll={el => scrollWithOffset(el)} onClick={handleSidebar}>
                      Garçom Web Delivery
                    </HashLink>
                  </li>
                  <li className={url.includes("garcom-web-comanda") ? "active" : ''}>
                    <Link to="garcom-web-comanda" onClick={handleSidebar} style={{ color: url.includes("garcom-web-comanda") ? '#138afd' : '' }}>
                      Garçom Web Comanda
                    </Link>
                  </li>
                  <li className={url.includes("garcom-web-fast") ? "active" : ''}>
                    <Link to="garcom-web-fast" onClick={handleSidebar} style={{ color: url.includes("garcom-web-fast") ? '#138afd' : '' }}>
                      Garçom Web Fast
                    </Link>
                  </li>
                  <li className={url.includes("garcom-web-live") ? "active" : ''}>
                    <Link to="garcom-web-live" onClick={handleSidebar} style={{ color: url.includes("garcom-web-live") ? '#138afd' : '' }}>
                      Garçom Web Live
                    </Link>
                  </li>
                  <li className={url.includes("garcom-web-link") ? "active" : ''}>
                    <Link to="garcom-web-link" onClick={handleSidebar} style={{ color: url.includes("garcom-web-link") ? '#138afd' : '' }}>
                      Garçom Web Link
                    </Link>
                  </li>
                  <li className={url.includes("garcom-web-office") ? "active" : ''}>
                    <HashLink smooth to="/" scroll={el => scrollWithOffset(el)} onClick={handleSidebar}>
                      Garçom Web Office
                    </HashLink>
                  </li>
                  <li className={url.includes("garcom-web-mt") ? "active" : ''}>
                    <HashLink smooth to="/" scroll={el => scrollWithOffset(el)} onClick={handleSidebar}>
                      Garçom Web MT
                    </HashLink>
                  </li>
                </ul>
              </UncontrolledCollapse>
            </li>
            <li>
              <Link to="contato" style={{ color: menuActive === "contato" ? '#138afd' : '' }} onClick={handleSidebar}>
                Contato
              </Link>
            </li>
          </ul>


          {isMobile &&
            <div className="btns-group ul-li mb-60 clearfix">

              {/* <h2 className="sidebar-title mb-30">support & downloads</h2>
            <p className="mb-45">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text.
            </p> */}
              <ul className="clearfix">
                <li>
                  <a href="https://www.garcomweb.com.br/painel" target={'_blank'} rel="noopener noreferrer" className={'store-btn bg-default-blue'}>
                    <span className="icon">
                      <i className="fas fa-key"></i>
                    </span>
                    <strong className="title-text">
                      <small>Acesso Restrito</small>
                      Painel
                    </strong>
                  </a>
                </li>
              </ul>
            </div>
          }



          <div className="contact-info ul-li-block mb-60 clearfix">
            <h2 className="sidebar-title mb-30">Localização</h2>
            <ul className="clearfix">
              <li>
                <span className="icon">
                  <i className="uil uil-map-marker"></i>
                </span>
                Rua Cônego Eugênio Leite, 433
              </li>
              <li>
                <span className="icon">
                  <i className="uil uil-map-pin"></i>
                </span>
                Pinheiros, São Paulo - SP
              </li>
              <li>
                <span className="icon">
                  <i className="uil uil-envelope-alt"></i>
                </span>
                contato@garcomweb.com.br
              </li>
              <li>
                <span className="icon">
                  <i className="uil uil-phone"></i>
                </span>
                +55 11 3082-8660
              </li>
            </ul>
          </div>

          {/* <div className="social-links ul-li clearfix">
            <h2 className="sidebar-title mb-30">Siga-nos</h2>
            <ul className="clearfix">
              <li>
                <Link to="#!">
                  <i className="fab fa-facebook-f"></i>
                </Link>
              </li>
              <li>
                <Link to="#!">
                  <i className="fab fa-twitter"></i>
                </Link>
              </li>
              <li>
                <Link to="#!">
                  <i className="fab fa-pinterest-p"></i>
                </Link>
              </li>
              <li>
                <Link to="#!">
                  <i className="fab fa-linkedin-in"></i>
                </Link>
              </li>
            </ul>
          </div> */}
        </div>
        <div
          onClick={handleSidebar}
          className={`overlay ${sidebarIsOpen ? "active" : null}`}
        ></div>
      </div>
    </Fragment>
  );
};

export default SideBar;
