import React from "react";
//import { Link } from "react-router-dom";
import TitleSection from "../Titlesection/TitleSection";
import { Container, Row, Col } from "reactstrap";

// import titleOne from "../../data/WorkingProgress/working-progress-two.json";
// const [{ title, subTitle, text }] = titleOne;


const GwLinkPagamentoOnline = ({ sectionSpace }) => {


  return (
    <section
      id="working-process-section"
      className={`working-process-section ${sectionSpace} clearfix`}
      style={{ paddingTop: 60 }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col sm="12" lg="6" md="8">
            <TitleSection spacer="mb-100" textAlign="text-center">
              <span className="sub-title mb-15">Pagamento Online</span>
              <h2 className="title-text mb-30">Pelo Site e Pelo App</h2>
              <p className="paragraph-text mb-0">
                Traga mais facilidade e segurança para seus clientes.
              </p>
              <p className="paragraph-text mb-0">
                Disponibilize diversas opções de pagamento.
              </p>
            </TitleSection>
          </Col>
        </Row>

        <Row className="justify-content-lg-start justify-content-md-center">
          <Col lg="4" md="8" xs="12">
            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/apple.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Apple Pay</h3>
                <p className="mb-0">
                  O Apple Pay é um sistema de pagamento por aproximação e carteira digital
                  desenvolvido pela Apple Inc. que possibilita aos usuários realizarem pagamentos
                  usando dispositivos da Apple.
                </p>
              </div>
            </div>

            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/google.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Google Pay</h3>
                <p className="mb-0">
                  Google Pay é uma plataforma de carteira digital e sistema de pagamento por
                  aproximação desenvolvido pela Google que permite através de um app tocar
                  para pagar compras em dispositivos Android.
                </p>
              </div>
            </div>
          </Col>

          <Col lg="4" md="8" xs="12">
            <div className="process-phone-image" >
              <span
                className="bg-image"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <img
                  src="assets/images/features/animation/6-img-1.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="phone-image"
                data-aos="zoom-in"
                data-aos-delay="700"
              >
                <img
                  src="assets/images/features/animation/gw-link1.7.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="shape-image-1"
                data-aos="fade-right"
                data-aos-delay="900"
              >
                <img
                  src="assets/images/features/animation/6-img-2.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="shape-image-2"
                data-aos="fade-left"
                data-aos-delay="1100"
              >
                <img
                  src="assets/images/features/animation/6-img-4.png"
                  alt="image_not_found"
                />
              </span>
            </div>
          </Col>

          <Col lg="4" md="8" xs="12">
            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/credit_card.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Cartão de Crédito</h3>
                <p className="mb-0">
                  O pagamento online via cartão de crédito oferece uma experiência
                  de compra moderna, eficaz e proporciona uma maneira fácil e segura de realizar
                  transações financeiras no mundo digital.
                </p>
              </div>
            </div>

            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/pix.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Pix</h3>
                <p className="mb-0">
                  O PIX permite que pessoas e empresas transfiram dinheiro instantaneamente entre
                  contas bancárias, 24 horas por dia, 7 dias por semana, incluindo feriados.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <div className="seemore-btn text-center">
          {/* <Link to="#" className="custom-btn-underline">
            see all features
          </Link> */}
        </div>
      </Container>
    </section>
  );
};

export default GwLinkPagamentoOnline;
