import React from "react";
import { Link } from "react-router-dom";
//import NewsLeter from "../NewsLetter/NewsLetter";

import { Container, Row, Col } from "reactstrap";
const Footer = ({ sectionSpace }) => {

  const handleGoTo = (e, goTo) => {
    e.preventDefault();

    const element = document.getElementById(goTo);
    const yOffset = -120;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });

    //elem && elem.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  return (
    <footer
      id="footer-section"
      className={`footer-section ${sectionSpace} clearfix`}
      style={{ backgroundImage: `url(assets/images/footer-bg-1.png)` }}
    >
      {/* <NewsLeter /> 
      <div className="btns-group ul-li-center mb-100 clearfix">
        <ul className="clearfix">
          <li>
            <a
              rel="noopener noreferrer"
              href="https://appleid.apple.com/"
              target="_blank"
              className="store-btn bg-default-blue"
            >
              <span className="icon">
                <i className="flaticon-apple"></i>
              </span>
              <strong className="title-text">
                <small>available on</small>
                apple store
              </strong>
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps"
              target="_blank"
              className="store-btn bg-default-pink"
            >
              <span className="icon">
                <i className="flaticon-google-play"></i>
              </span>
              <strong className="title-text">
                <small>available on</small>
                google play
              </strong>
            </a>
          </li>
        </ul>
      </div>
*/}
      <div
        className="footer-content mb-100 clearfix"
        data-aos="fade-up"
        data-aos-duration="450"
        data-aos-delay="200"
      >
        <Container>
          <Row>
            <Col lg="3" md="6">
              <div className="about-content">
                <div className="brand-logo mb-30">
                  <Link to="/" className="brand-link">
                    <img
                      src="assets/images/logo/logo-2.png"
                      alt="logo_not_found"
                    />
                  </Link>
                </div>
                <p className="mb-30 ">
                  O futuro já chegou! <br /> Escolha o Garçom Web e faça  <br />parte dessa nova era.
                </p>
                {/* <div className="social-links ul-li clearfix">
                  <ul className="clearfix">
                    <li>
                      <Link to="/">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i className="fab fa-pinterest-p"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li>
                  </ul>
                </div> */}
                <a href="https://stone.com.br" target={"_blank"} rel="noopener noreferrer">
                  <img
                    src={`https://storage.googleapis.com/partnerhub_partner_badges_prod/D_62a3a8871afcde00197b95d0.png`}
                    alt="image_not_found"
                    style={{ height: 80 }}
                  />
                </a>
              </div>
            </Col>

            <Col lg="2" md="6">
              <div className="useful-links ul-li-block clearfix">
                <h3 className="item-title">Home</h3>
                <ul className="clearfix">
                  <li>
                    <Link to="home">Home</Link>
                  </li>
                  <li>
                    <Link to="quem-somos">Quem Somos</Link>
                  </li>
                </ul>
              </div>
            </Col>
            {/* <Col lg="2" md="6">
              <div className="useful-links ul-li-block clearfix">
                <h3 className="item-title">Home</h3>
                <ul className="clearfix">
                  <li>
                    <Link to="#" style={{ textTransform: 'none' }} >Revendas</Link>
                  </li>
                  <li>
                    <Link to="#" style={{ textTransform: 'none' }} >Política de Privacidade</Link>
                  </li>
                  <li>
                    <Link to="#" style={{ textTransform: 'none' }}>Termos de uso</Link>
                  </li>
                </ul>
              </div>
            </Col> */}

            {/* <Col lg="2" md="6">
              <div className="useful-links ul-li-block clearfix">
                <h3 className="item-title">Sobre nós</h3>
                <ul className="clearfix">
                  <li>
                    <Link to="quem-somos">Quem Somos</Link>
                  </li>
                  <li>
                    <Link to="#">Portfolio</Link>
                  </li>
                  <li>
                    <Link to="#">Suporte</Link>
                  </li>
                  <li>
                    <Link to="/">Trabalhe Conosco</Link>
                  </li>
                  <li>
                    <Link to="/">FAQ</Link>
                  </li>
                </ul>
              </div>
            </Col> */}

            <Col lg="2" md="6">
              <div className="useful-links ul-li-block clearfix">
                <h3 className="item-title">Produtos</h3>
                <ul className="clearfix">
                  <li>
                    <a href="#!" onClick={(e) => handleGoTo(e, 'balcao')} >
                      Garçom Web Balcão
                    </a>
                    {/* <Link to="home">Garçom Web Balcão</Link> */}
                  </li>
                  <li>
                    <a href="#!" onClick={(e) => handleGoTo(e, 'mesa')} >
                      Garçom Web Mesa
                    </a>
                    {/* <Link to="/">Garçom Web Mesa</Link> */}
                  </li>
                  <li>
                    <a href="#!" onClick={(e) => handleGoTo(e, 'mesa')} >
                      Garçom Web Cartão
                    </a>
                    {/* <Link to="/">Garçom Web Cartão</Link> */}
                  </li>
                  <li>
                    <a href="#!" onClick={(e) => handleGoTo(e, 'delivery')} >
                      Garçom Web Delivery
                    </a>
                    {/* <Link to="/">Garçom Web Delivery</Link> */}
                  </li>

                  <li>
                    <Link to="garcom-web-comanda">Garçom Web Comanda</Link>
                  </li>
                  <li>
                    <Link to="garcom-web-fast">Garçom Web Fast</Link>
                  </li>
                  <li>
                    <Link to="garcom-web-live">Garçom Web Live</Link>
                  </li>
                  <li>
                    <Link to="garcom-web-link">Garçom Web Link</Link>
                  </li>
                  <li>
                    <Link to="/">Garçom Web Office</Link>
                  </li>
                  <li>
                    <Link to="/">Garçom Web MT</Link>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg="3" md="6">
              <div className="contact-info ul-li-block clearfix">
                <h3 className="item-title">Contato</h3>
                <ul className="clearfix">
                  <li>
                    <a href="tel:+551130828660">
                      <small className="icon">
                        <i className="uil uil-phone-pause"></i>
                      </small>
                      <span className="info-text">+55 11 3082-8660</span>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:contato@garcomweb.com.br">
                      <small className="icon">
                        <i className="uil uil-envelope-minus"></i>
                      </small>
                      <span className="info-text text-lowercase">contato@garcomweb.com.br</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://goo.gl/maps/RFt6KYLv6nmd6dbZ7" target={"_blank"} rel="noopener noreferrer">
                      <small className="icon">
                        <i className="uil uil-location-point"></i>
                      </small>
                      <span className="info-text">
                        Rua Cônego Eugênio Leite, 433. Pinheiros, São Paulo - SP
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <div className="footer-bottom clearfix">
          <Row>
            <Col md="6">
              <p className="copyright-text mb-0">
                Copyright @ {new Date().getFullYear()}{" "}
                <Link
                  to={"/"}
                  rel="noopener noreferrer"
                  className="author-link"
                >
                  Garçom Web
                </Link>{" "}
                all right reserved.
              </p>
            </Col>

            <Col md="6">
              <div className="useful-links ul-li-right clearfix">
                <ul className="clearfix">
                  <li>
                    <Link to="/" style={{ textTransform: 'none' }}>Termos de Uso</Link>
                  </li>
                  <li>
                    <Link to="/" style={{ textTransform: 'none' }}>Política de Privacidade</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
