import React from "react";
//import { Link } from "react-router-dom";
import TitleSection from "../Titlesection/TitleSection";
import { Container, Row, Col } from "reactstrap";

// import titleOne from "../../data/WorkingProgress/working-progress-two.json";
// const [{ title, subTitle, text }] = titleOne;


const HowItWorksGwLink = ({ sectionSpace }) => {


  return (
    <section
      id="working-process-section"
      className={`working-process-section ${sectionSpace} clearfix`}
      style={{ paddingTop: 60 }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col sm="12" lg="6" md="8">
            <TitleSection spacer="mb-100" textAlign="text-center">
              <span className="sub-title mb-15">Como funciona</span>
              <h2 className="title-text mb-30">Delivery e Auto Atendimento</h2>
              <p className="paragraph-text mb-0">
                Tenha o seu próprio app e site de delivery e app de auto atendimento. Configure e gerencie tudo através do portal web.
              </p>
            </TitleSection>
          </Col>
        </Row>

        <Row className="justify-content-lg-start justify-content-md-center">
          <Col lg="4" md="8" xs="12">
            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/chart.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Dashboard</h3>
                <p className="mb-0">
                  O portal já vem com Dashboard inclusa, a qual exibe dados relevantes,
                  que te ajudam a tomar decisões e bolar estratégias.
                </p>
              </div>
            </div>

            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/delivery.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Delivery</h3>
                <p className="mb-0">
                  Configure sua loja com sua marca,
                  determine a taxa de entrega, formas de pagamento e muito mais.
                </p>
              </div>
            </div>
          </Col>

          <Col lg="4" md="8" xs="12">
            <div className="process-phone-image" >
              <span
                className="bg-image"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <img
                  src="assets/images/features/animation/6-img-1.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="phone-image"
                data-aos="zoom-in"
                data-aos-delay="700"
              >
                <img
                  src="assets/images/features/animation/gw-link1.2.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="shape-image-1"
                data-aos="fade-right"
                data-aos-delay="900"
              >
                <img
                  src="assets/images/features/animation/6-img-2.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="shape-image-2"
                data-aos="fade-left"
                data-aos-delay="1100"
              >
                <img
                  src="assets/images/features/animation/6-img-4.png"
                  alt="image_not_found"
                />
              </span>
            </div>
          </Col>

          <Col lg="4" md="8" xs="12">
            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/auto-atendimento.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Auto Atendimento</h3>
                <p className="mb-0">
                  Permita ao seus clientes que estão no seu estabelecimento, que façam o seu pedido, através do próprio celular.
                </p>
              </div>
            </div>

            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/icons/mobile-pc.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">PC e Mobile</h3>
                <p className="mb-0">
                  O Garçom Web Link foi projetado para ser utilizado em qualquer plataforma,
                  tanto no smartphone, quanto no PC.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <div className="seemore-btn text-center">
          {/* <Link to="#" className="custom-btn-underline">
            see all features
          </Link> */}
        </div>
      </Container>
    </section>
  );
};

export default HowItWorksGwLink;
