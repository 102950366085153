import React, { Fragment, useRef, useEffect } from "react";

import { Container, Row, Col } from "reactstrap";
import Parallax from "parallax-js";
//import { Link } from 'react-router-dom';

const BannerHomeApp = ({ sectionSpace }) => {
  const scenElement = useRef(null);


  const handleGoToApps = (e) => {
    let hero = document.getElementById("apps");
    e.preventDefault();
    hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });
  }


  useEffect(() => {
    const parallaxInstance = new Parallax(scenElement.current, {
      relativeInput: true,
    });
    parallaxInstance.enable();
    return () => parallaxInstance.disable();
  }, []);

  return (
    <Fragment>
      <section
        id="banner-section"
        className={`banner-section ${sectionSpace} clearfix`}
      >
        <div className="mobile-app-banner-6">
          <Container>
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="7" md="8" xs="12">
                {/* <!-- show on mobile device - start --> */}
                <div className="mobile-banner-image d-none">
                  <img
                    src="assets/images/features/animation/gw-fast.png"
                    alt="image_not_found"
                  />
                </div>
                {/* <!-- show on mobile device - end --> */}
                <div className="banner-content">
                  <h2>
                    Aplicativos Garçom Web
                    <strong></strong>
                  </h2>
                  <p>
                    Temos soluções em aplicativos, desde o garçom tirar o pedido do cliente, ou o próprio cliente realizar o seu auto atendimento,
                    e até o dono visualizar em tempo real, tudo o que acontece na sua empresa.
                  </p>
                  <div className="btns-group ul-li clearfix">
                    <ul className="clearfix">
                      <li>
                        <a href="#!" onClick={handleGoToApps} className="custom-btn">
                          Saiba Mais
                        </a>
                      </li>
                      {/* <li>
                        <a
                          rel="noopener noreferrer"
                          href="https://appleid.apple.com/"
                          target="_blank"
                          className="store-btn bg-default-black"
                        >
                          <span className="icon">
                            <i className="flaticon-apple"></i>
                          </span>
                          <strong className="title-text">
                            <small>disponível na</small>
                            app store
                          </strong>
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          href="https://play.google.com/store/apps"
                          target="_blank"
                          className="store-btn bg-default-pink"
                        >
                          <span className="icon">
                            <i className="flaticon-google-play"></i>
                          </span>
                          <strong className="title-text">
                            <small>disponível na</small>
                            google play
                          </strong>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </Col>

              <Col lg="3">
                <div
                  className="banner-image scene clearfix"
                  id="scene"
                  ref={scenElement}
                >
                  <span className="phone-image float-right" data-depth="0.2" >
                    <small
                      data-aos="zoom-out"
                      data-aos-duration="500"
                      data-aos-delay="200"
                    >
                      <img
                        //src="assets/images/features/animation/gw-comanda2.png"
                        src="assets/images/features/animation/gw-fast.png"
                        alt="image_not_found"
                      />
                    </small>
                  </span>
                  <span className="commentbar-image-4" data-depth="0.5">
                    <small
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      data-aos-delay="700"
                    >
                      <img
                        //src="assets/images/features/animation/gw-comanda1.png"
                        src="assets/images/features/animation/gw-live.png"
                        alt="image_not_found"
                      />
                    </small>
                  </span>
                  <span className="commentbar-image-3" data-depth="0.5" >
                    <small
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      data-aos-delay="1000"
                    >
                      <img
                        //src="assets/images/features/animation/gw-comanda1.1.png"
                        src="assets/images/features/animation/gw-link.png"
                        alt="image_not_found"
                      />
                    </small>
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
          <span className="shape-bg-image">
            <img
              src="assets/images/banner/mab-6-img-1.png"
              alt="image_not_found"
            />
          </span>
        </div>
      </section>
    </Fragment>
  );
};

export default BannerHomeApp;
