import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
//import { VideoModal } from 'components/Modal';
//import Parallax from "parallax-js";
//import featureDataOne from "../../data/feature/feature-one.json";
//import featureDataTwo from "../../data/feature/feature-two.json";
//import Button from '../../components/Button/Button'

//const [{ paragraph1, paragraph2, subTitle, title, span }] = featureDataOne;
const SectionApps = () => {

  // const scenElement = useRef(null);

  // useEffect(() => {
  //   const parallaxInstance = new Parallax(scenElement.current, {
  //     relativeInput: true,
  //   });
  //   parallaxInstance.enable();
  //   return () => parallaxInstance.disable();
  // }, []);

  return (
    <Fragment>
      <section
        id="apps"
        className="features-section sec-ptb-160 clearfix"
      >
        <Container>
          <div className="feature-item" id="comanda">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="8">
                <div className="feature-content">
                  <div className="section-title mb-30">
                    <span className="sub-title mb-15">Garçom Web Comanda</span>
                    <h2 className="title-text mb-0">
                      App de Comanda Eletrônica
                      {/* <span>{span}</span> */}
                    </h2>
                  </div>

                  <p className="mb-60">
                    O Aplicativo Gw Comanda, é o app de comanda eletrônica do sistema Garçom Web. Através dele o atendente realiza o pedido do cliente de forma fácil, rápida e muito intuitiva.
                    Disponível para Android e iOS.
                  </p>

                  <div className="info-list ul-li-block mb-45 clearfix">
                    <ul className="clearfix">
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Certificado e integrado aos POS: PagSeguro, Rede, Getnet, Stone.
                          <span>Com a maquininha de cartão, você tira o pedido do cliente e também realiza o pagamento. Tudo sem sair do nosso app.</span>
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Pedido por módulos, mesa, cartão, terminal de lançamento e fast
                        </span>
                      </li>

                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Conferência da conta com opção de impressão na impressora bluetooth ou impressora da rede
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Mapa das Mesas detalhado
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Envio de mensagens entre os demais dispositivos
                        </span>
                      </li>


                    </ul>
                  </div>

                  <div className="btns-group ul-li clearfix">
                    <ul className="clearfix">
                      {/* <li>
                        <Button btnType="store-btn" btnColor="bg-default-blue">
                          <span className="icon">
                            <i className="flaticon-apple"></i>
                          </span>
                          <strong className="title-text">
                            <small>Disponível na</small>
                            App Store
                          </strong>
                        </Button>
                      </li>
                      <li>
                        <Button btnType="store-btn" btnColor="bg-default-pink">
                          <span className="icon">
                            <i className="flaticon-google-play"></i>
                          </span>
                          <strong className="title-text">
                            <small>Disponível na</small>
                            Google Play
                          </strong>
                        </Button>
                      </li> */}
                      <li>
                        <Link
                          to="/garcom-web-comanda"
                          className="custom-btn-underline"
                          style={{ textTransform: 'none' }}
                        >
                          Ver todos os recursos
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>

              <Col lg="5" md="5" >
                <div
                  id="scene"

                  className="feature-image-2 "
                >
                  <span
                    className="bg-image aos-init aos-animate"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img
                      src="assets/images/features/animation/6-img-1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="phone-image aos-init aos-animate"
                    data-aos="zoom-out"
                    data-aos-delay="500"
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda.png"
                      alt="image_not_found"
                    />
                  </span>
                  {/* <span
                    className="comment-image-1 aos-init aos-animate"
                    data-aos="fade-left"
                    data-aos-delay="700"
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda-1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="comment-image-2 aos-init aos-animate"
                    data-aos="fade-right"
                    data-aos-delay="900"
                  >
                    <img
                      src="assets/images/features/animation/gw-comanda-2.png"
                      alt="image_not_found"
                    />
                  </span> */}
                  <span
                    className="shape-image-1 aos-init aos-animate"
                    data-aos="fade-right"
                    data-aos-delay="1100"
                  >
                    <img
                      src="assets/images/features/animation/6-img-2.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="shape-image-2 aos-init aos-animate"
                    data-aos="fade-left"
                    data-aos-delay="1200"
                  >
                    <img
                      src="assets/images/features/animation/6-img-3.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="shape-image-3 aos-init"
                    data-aos="fade-right"
                    data-aos-delay="1300"
                  >
                    <img
                      src="assets/images/features/animation/6-img-4.png"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </div>

          <div className="feature-item" id="live">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="5">
                <div className="feature-image-2" >
                  <span
                    className="bg-image aos-init aos-animate"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img
                      src="assets/images/features/animation/6-img-1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="phone-image aos-init aos-animate"
                    data-aos="zoom-out"
                    data-aos-delay="500"
                  >
                    <img
                      src="assets/images/features/animation/gw-live.png"
                      alt="image_not_found"
                    />
                  </span>
                  {/* <span
                    className="comment-image-1 aos-init aos-animate"
                    data-aos="zoom-in"
                    data-aos-delay="700"
                    style={{ left: 26, top: 0 }}
                  >
                    <img
                      src="assets/images/features/animation/gw-live-2.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="comment-image-2 aos-init aos-animate"
                    data-aos="fade-right"
                    data-aos-delay="900"
                  >
                    <img
                      src="assets/images/features/animation/gw-live-2.png"
                      alt="image_not_found"
                    />
                  </span> */}
                  <span
                    className="shape-image-1 aos-init aos-animate"
                    data-aos="fade-right"
                    data-aos-delay="1100"
                  >
                    <img
                      src="assets/images/features/animation/6-img-2.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="shape-image-2 aos-init aos-animate"
                    data-aos="fade-left"
                    data-aos-delay="1200"
                  >
                    <img
                      src="assets/images/features/animation/6-img-3.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="shape-image-3 aos-init"
                    data-aos="fade-right"
                    data-aos-delay="1300"
                  >
                    <img
                      src="assets/images/features/animation/6-img-4.png"
                      alt="image_not_found"
                    />
                  </span>
                  {/* <VideoModal /> */}
                </div>
              </Col>

              <Col lg="6" md="8">
                <div className="feature-content">

                  <div className="section-title mb-30">
                    <span className="sub-title mb-15">
                      Garçom Web Live
                    </span>
                    <h2 className="title-text mb-0">
                      Sua empresa em tempo real
                      {/* <span>{featureDataTwo.span}</span> */}
                    </h2>
                  </div>

                  <p className="mb-30">
                    Ter informações na palma da mão, hoje em dia, é obrigatório, e com o Garçom Web Live, isso é natural.
                    Com ele você acompanha em tempo real, tudo o que acontece em seu estabelecimento.
                  </p>

                  <div className="info-list ul-li-block mb-45 clearfix">
                    <ul className="clearfix">
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Faturamento
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Ticket Médio
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Produtos Vendidos
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Mesas e Entregas em Aberto
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Cancelamentos
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Transferências
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Descontos
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          e muito mais
                        </span>
                      </li>
                    </ul>
                  </div>

                  <Link to="/garcom-web-live" className="custom-btn-underline m-0">
                    + Detalhes
                  </Link>
                </div>
              </Col>
            </Row>
          </div>

          <div className="feature-item" id="link">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="8">
                <div className="feature-content">
                  <div className="section-title mb-30">
                    <span className="sub-title mb-15">Garçom Web Link</span>
                    <h2 className="title-text mb-0">
                      Seu app de Delivery
                      {/* <span>{span}</span> */}
                    </h2>
                  </div>

                  <p className="mb-30">
                    Bem sabemos que existem diversas ferramentas de delivery hoje em dia, mas todas elas cobram um alto custo e te expôe a concorrência.
                  </p>
                  <p className="mb-60">
                    Com o Garçom Web Link, você tem o seu próprio app de delivery, totalmente integrado com o sistema Garçom Web, ou seja, o cliente faz o pedido,
                    e o Garçom Web faz o resto.
                  </p>

                  <div className="info-list ul-li-block mb-45 clearfix">
                    <ul className="clearfix">
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Configure tudo facilmente através do portal
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Dashboard completa
                        </span>
                      </li>

                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Bloqueie ou desbloqueie produtos para venda, de maneira simples
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          Controle o status da sua loja em um click
                        </span>
                      </li>
                      <li>
                        <small className="icon">
                          <i className="flaticon-checked"></i>
                        </small>
                        <span className="info-text">
                          e muito mais
                        </span>
                      </li>


                    </ul>
                  </div>

                  <div className="btns-group ul-li clearfix">
                    <ul className="clearfix">
                      {/* <li>
                        <Link to="/pricing-page" className="custom-btn">
                          try it free
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to="/garcom-web-link"
                          className="custom-btn-underline"
                          style={{ textTransform: 'none' }}
                        >
                          Saiba mais
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>

              <Col lg="5" md="5">
                <div className="feature-image-2">
                  <span
                    className="bg-image aos-init aos-animate"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img
                      src="assets/images/features/animation/6-img-1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="phone-image aos-init aos-animate"
                    data-aos="zoom-out"
                    data-aos-delay="500"
                  >
                    <img
                      src="assets/images/features/animation/gw-link.png"
                      alt="image_not_found"
                    />
                  </span>
                  {/* <span
                    className="comment-image-1 aos-init aos-animate"
                    data-aos="fade-left"
                    data-aos-delay="700"
                  >
                    <img
                      src="assets/images/features/animation/gw-link-1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="comment-image-2 aos-init aos-animate"
                    data-aos="fade-left"
                    data-aos-delay="900"
                  >
                    <img
                      src="assets/images/features/animation/1-img-4.png"
                      alt="image_not_found"
                    />
                  </span> */}
                  <span
                    className="shape-image-1 aos-init aos-animate"
                    data-aos="fade-right"
                    data-aos-delay="1100"
                  >
                    <img
                      src="assets/images/features/animation/6-img-2.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="shape-image-2 aos-init aos-animate"
                    data-aos="fade-left"
                    data-aos-delay="1200"
                  >
                    <img
                      src="assets/images/features/animation/6-img-3.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="shape-image-3 aos-init"
                    data-aos="fade-right"
                    data-aos-delay="1300"
                  >
                    <img
                      src="assets/images/features/animation/6-img-4.png"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </Fragment>
  );
};

export default SectionApps;
