import { ContatoType, DefaultReturnType } from 'types';
import http from './http-common';

class ApiService {

  EnviaMensagemContato(form: ContatoType) {
    return http.post<DefaultReturnType>('/office/Dashboard/EnviaMensagemContato', form)
  }


}

export default new ApiService();